import React from "react";

interface MessageWithClickableLinkProps {
  messageContent: string;
}

const MessageWithClickableLink: React.FC<MessageWithClickableLinkProps> = ({ messageContent }) => {
  // Regex to match URLs, including those that may end with a closing parenthesis
  const linkRegex = /(https?:\/\/[^\s)]+)/g; // Match URL without ending parentheses

  // Replace multiple newline characters with <br> tags
  const formattedMessageContent = messageContent
    .replace(/\n\n/g, "<br><br>")
    .replace(/\\n/g, "<br>");

  // Replace links with clickable HTML links
  const cleanMessageContent = formattedMessageContent.replace(linkRegex, (url) => {
    const cleanLink = url.replace(/\.$/, ""); // Remove the period if it's at the end
    return `<a href="${cleanLink}" target="_blank" rel="noopener noreferrer">${cleanLink}</a>`;
  });

  return (
    <span style={{ padding: "1rem 0" }} dangerouslySetInnerHTML={{ __html: cleanMessageContent }} />
  );
};

export default MessageWithClickableLink;
