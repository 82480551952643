import { Fab as MuiFab } from "@mui/material";
import React from "react";

export default function Fab({ icon, ...props }: any) {
  return (
    <MuiFab
      sx={{ borderRadius: ".5rem", width: 48, height: 48, boxShadow: "none" }}
      {...props}
      variant="extended"
    >
      {icon}
    </MuiFab>
  );
}
