import {
  Box,
  Container,
  Grid,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import LineIcon from "../assets/icon/line";

export default function Setting() {
  const navigate = useNavigate(); // Get the navigate function

  const theme = useTheme();
  const handleNavigate = (to: string) => {
    navigate(to); // Navigate to the details route
  };
  const { pathname } = useLocation();

  const dealership = pathname.includes("dealership");
  const businessHours = pathname.includes("business-hours");
  const lead = pathname.includes("lead");

  return (
    <Container maxWidth="xl">
      <Box display={"flex"} justifyContent={"space-between"} flexWrap={"wrap"}>
        <Box flex={1} mb={2}>
          <Typography variant="h4" fontWeight={600}>
            Settings
          </Typography>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item md={3} xs={12}>
            <Box bgcolor={"white"} py={2} px={2} borderRadius={4}>
              <Typography variant="h6" gutterBottom>
                Dealership Setting
              </Typography>
              <MenuList sx={{ pt: 0 }}>
                <MenuItem onClick={() => handleNavigate("dealership")}>
                  <ListItemText
                    sx={{
                      color: dealership ? theme.palette.primary.main : "",
                    }}
                  >
                    Dealer Information
                  </ListItemText>
                  {dealership && (
                    <Box position={"absolute"} left={0} display={"flex"}>
                      <LineIcon fill={theme.palette.primary.main} />
                    </Box>
                  )}
                </MenuItem>
                <MenuItem onClick={() => handleNavigate("business-hours")}>
                  <ListItemText
                    sx={{
                      color: businessHours ? theme.palette.primary.main : "",
                    }}
                  >
                    Business Hours
                  </ListItemText>
                  {businessHours && (
                    <Box position={"absolute"} left={0} display={"flex"}>
                      <LineIcon fill={theme.palette.primary.main} />
                    </Box>
                  )}
                </MenuItem>
                <MenuItem onClick={() => handleNavigate("lead")}>
                  <ListItemText
                    sx={{
                      color: lead ? theme.palette.primary.main : "",
                    }}
                  >
                    Leads Source
                  </ListItemText>
                  {lead && (
                    <Box position={"absolute"} left={0} display={"flex"}>
                      <LineIcon fill={theme.palette.primary.main} />
                    </Box>
                  )}
                </MenuItem>
              </MenuList>
              <Typography variant="h6" gutterBottom>
                A.I. setting
              </Typography>
            </Box>
          </Grid>
          <Grid item md={9} xs={12} display={"flex"} flexDirection={"column"} gap={3}>
            <Outlet />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
