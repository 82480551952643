import React from "react";
import Menu from "./menu/menu";
import { Badge, IconButton, MenuItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getMode, modeSet } from "../redux/layoutSlice";
import { ref, update } from "firebase/database";
import { firebaseDb } from "../firebase/app";
import { getUid } from "../redux/authSlice";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import { useNavigate } from "react-router";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import { changeNotif } from "../redux/userSlice";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { getDataByQL } from "../utils/request";
import LogoutIcon from "@mui/icons-material/Logout";
import LogOut from "../sections/auth/logout";

export default function NavMenu({ anchorEl, handleClose }: any) {
  const dispatch = useDispatch();
  const mode = useSelector(getMode);
  const uid = useSelector(getUid);
  const navigate = useNavigate();
  const [openLogout, setOpenLogout] = React.useState(false);
  const [unreadCounter, setUnreadCounter]: any = React.useState(0);

  const unReadConLength = () => {
    getDataByQL(`conversations/${uid}`, "read", false).then((r: number) => setUnreadCounter(r));
  };

  React.useEffect(() => {
    unReadConLength();
  }, []);

  function handleNotificationOpen() {
    dispatch(changeNotif(true));
  }

  const handleLogout = () => {
    handleClose();
    setOpenLogout(!openLogout);
  };

  return (
    <>
      <Menu anchorEl={anchorEl} handleClose={handleClose}>
        <MenuItem
          onClick={() => {
            dispatch(modeSet(mode === "dark" ? "light" : "dark"));
            handleClose();
            const data = {
              theme: mode === "dark" ? "light" : "dark",
            };
            const userPreferences = {
              darkMode: mode === "dark" ? false : true,
            };
            localStorage.setItem("userPreferences", JSON.stringify(userPreferences));
            update(ref(firebaseDb, `dealers/${uid}/info/dashboardSettings`), data);
          }}
        >
          <IconButton sx={{ color: "black" }}>
            {mode === "dark" ? <LightModeRoundedIcon /> : <DarkModeRoundedIcon />}
          </IconButton>
          <Typography fontWeight={500}>{mode === "dark" ? "Light" : "Dark"}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/setting/dealership");
            handleClose();
          }}
          disabled
        >
          <IconButton sx={{ color: "black" }}>
            <SettingsRoundedIcon />
          </IconButton>
          <Typography fontWeight={500}>Setting</Typography>
        </MenuItem>
        <MenuItem onClick={handleNotificationOpen}>
          <IconButton sx={{ color: "black" }}>
            <NotificationsIcon />
            <Badge badgeContent={unreadCounter} color="primary" />
          </IconButton>
          <Typography fontWeight={500}>Notification</Typography>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <IconButton sx={{ color: "black" }}>
            <LogoutIcon />
          </IconButton>
          <Typography fontWeight={500}>Log Out</Typography>
        </MenuItem>
      </Menu>
      <LogOut open={openLogout} handleClose={handleLogout} />
    </>
  );
}
