import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { firebaseDb } from "../../firebase/app";
import { push, ref } from "firebase/database";
import { getUid } from "../../redux/authSlice";
import { useSelector } from "react-redux";
import { getActiveConId } from "../../redux/userSlice";
import Dialog from "../../components/dialog/dialog";
import Button from "../../components/button/button";
import ControlledInput from "../../components/input/controlled-input";
import Snackbar from "../../components/snackbar/snackbar";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  name: yup
    .string()
    .required("full name is required")
    .min(3, "Full name must be at least 3 characters long")
    .max(20, "Full name must be at maximum 20 characters long"),
  email: yup.string().required().email("Invalid email"),
  description: yup.string().required("description is required").min(3).max(255),
  phoneNumber: yup.string().required("Phone number is required"),
});

const defaultValues: any = {
  name: "",
  email: "",
  phoneNumber: "",
  description: "",
};

export default function BugDialog({ open, handleClose }: any) {
  const uid = useSelector(getUid);
  const activeConId = useSelector(getActiveConId);
  const [notify, setNotify] = useState({ message: "", open: false, type: "" });

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<any>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = () => {
    const { name, email, phoneNumber, description }: any = getValues();
    const data = {
      status: 0,
      bug: true,
      name: name,
      email: email,
      description: description,
      phoneNumber: phoneNumber,
    };
    push(ref(firebaseDb, `reports/${uid}/${activeConId}`), data)
      .then(() => {
        handleClose();
        console.log(data);
        setNotify({ message: "Success", open: true, type: "" });
        reset();
      })
      .catch((e) => {
        setNotify({ message: e.message, open: true, type: "error" });
      });
  };

  const renderTitle = "Report Issues";

  const renderContent = (
    <Box>
      <Typography variant="body2">
        Please report any issues with A.I. or software in this conversation. Our Support Team will
        fix it as soon as possible.
      </Typography>
      <Box my={1}>
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <ControlledInput
              label={"description"}
              placeholder="Please explain about the issue here"
              error={!!errors.description}
              multiline
              rows={8}
              id="description"
              {...field}
            />
          )}
        />
      </Box>
      <Box display={"grid"} gap={1} mt={1}>
        <Typography variant="body2">
          Please add your contact information so we our technical team can call you once issue is
          fixed.
        </Typography>
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <ControlledInput
              label="Full Name"
              error={!!errors.name}
              id="name"
              placeholder="Enter Your Full Name "
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="phoneNumber"
          render={({ field }) => (
            <ControlledInput
              error={!!errors.phoneNumber}
              id="phoneNumber"
              placeholder="Enter Your phone number"
              label="Phone Number"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <ControlledInput
              error={!!errors.email}
              label="Email"
              id="email"
              placeholder="Enter Your email"
              {...field}
            />
          )}
        />
      </Box>
    </Box>
  );

  const renderAction = (
    <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
      <Button variant="outlined" color="primary" onClick={handleClose}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={!isValid}
        onClick={onSubmit}
        sx={{ width: "100%" }}
      >
        Submit
      </Button>
    </Box>
  );

  return (
    <>
      <Dialog
        onSubmit={handleSubmit(onSubmit)}
        fullWidth
        open={open}
        handleClose={handleClose}
        renderTitle={renderTitle}
        renderContent={renderContent}
        renderActions={renderAction}
        showCloseButton={false}
      />
      <Snackbar
        handleClose={() => setNotify({ message: "", open: false, type: "" })}
        message={notify.message}
        open={notify.open}
        type={notify.type}
      />
    </>
  );
}
