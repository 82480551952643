import React, { useState } from "react";
import ControlledBox from "../../components/box/controlled-box";
import { Avatar, Box, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import Button from "../../components/button/button";
import ControlledInput from "../../components/input/controlled-input";

export default function Lead() {
  const [state, setState] = useState("");
  const handleChange = (e: any) => {
    setState(e.target.value);
  };
  return (
    <>
      <ControlledBox>
        <Box>
          <Typography color={"#505050"} variant="h6">
            Leads Source
          </Typography>
          <Typography color={"#9B9B9B"} variant="body1">
            Control how this number works a differ times of day
          </Typography>
        </Box>
        <Grid container spacing={1} my={2}>
          {Array.from({ length: 10 }, (_, i) => (
            <Grid item md={4} xs={6} key={i + 1}>
              <Box display={"flex"} gap={1} alignItems={"center"}>
                <FormControlLabel control={<Checkbox defaultChecked />} label="" />
                <Avatar alt="cold-lead-state" src="https://www.cars.com/images/favicon_1.png">
                  {i + 1}
                </Avatar>
                <Typography variant="body1">Cars.com</Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box display={"flex"} gap={1} alignItems={"end"}>
          <Box display={"flex"} width={{ xs: "100%", md: 500 }}>
            <ControlledInput
              placeholder="Enter Your Name"
              value={state}
              label="Source Name"
              name="dealerName"
              onChange={handleChange}
            />
          </Box>
          <Box display={"flex"} alignItems={"end"}>
            <Button variant="contained" color="primary">
              Add source
            </Button>
          </Box>
        </Box>
      </ControlledBox>
      <Box display={"flex"} justifyContent={"left"} width={"100%"} gap={1}>
        <Button variant="outlined" color="primary">
          Cancel
        </Button>
        <Button variant="contained" color="primary">
          Save Change
        </Button>
      </Box>
    </>
  );
}
