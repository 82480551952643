import CloseIcon from "@mui/icons-material/Close";
import { Dialog as MuiDialog, Stack, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import * as React from "react";

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  showCloseButton?: boolean;
  handleClose?: any;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, showCloseButton = true, handleClose, ...other } = props;

  return (
    <Stack
      sx={{
        alignItems: "center",
        display: "flex",
        fontSize: 16,
        flexDirection: "row",
        justifyContent: "space-between",
      }}
      {...other}
    >
      <Typography variant="body1" fontWeight={700}>
        {children}
      </Typography>
      {showCloseButton && (
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      )}
    </Stack>
  );
}

export interface DialogProps {
  open: boolean;
  handleClose: () => void;
  renderTitle?: React.ReactNode;
  renderContent?: React.ReactNode;
  renderActions?: React.ReactNode;
  onSubmit?: any;
  fullWidth?: boolean;
  maxWidth?: "xs|sm|md|lg|xl" | any;
  showCloseButton?: boolean;
  maxHeight?: any;
  display?: any;
  filter?: any;
}

export default function Dialog(props: DialogProps) {
  const {
    open,
    display,
    handleClose,
    renderTitle,
    renderContent,
    renderActions,
    onSubmit,
    fullWidth = false,
    maxWidth = "sm",
    showCloseButton,
  } = props;

  const BootstrapDialog = React.useMemo(() => {
    return styled(MuiDialog)(({ theme }) => ({
      "& .MuiDialog-paper": {
        padding: theme.spacing(2),
        borderRadius: 20,
      },
      "& .MuiDialogActions-root": {
        padding: theme.spacing(2, 1),
      },
      "& .MuiDialogContent-root": {
        minWidth: 328,
        minHeight: 72,
        padding: 0,
        display: "flex",
        alignItems: "center",
      },
    }));
  }, []);

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={handleClose}
      open={open}
      sx={{
        maxWidth: maxWidth,
        mx: "auto",
        display: { xs: display || "block", sm: "block" },
      }}
    >
      <BootstrapDialogTitle
        handleClose={handleClose}
        id="customized-dialog-title"
        showCloseButton={showCloseButton}
      >
        {renderTitle}
      </BootstrapDialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>{renderContent}</DialogContent>
        {renderActions && <DialogActions>{renderActions}</DialogActions>}
      </form>
    </BootstrapDialog>
  );
}
