import { SvgIcon } from "@mui/material";
import React from "react";

export default function AppointmentIcon({ ...props }: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <g clipPath="url(#clip0_1807_12212)">
        <path d="M0.5 2.5C0.5 1.39543 1.39543 0.5 2.5 0.5H11.5C12.6046 0.5 13.5 1.39543 13.5 2.5V7.19032C12.8921 6.90775 12.2145 6.75 11.5 6.75C8.87665 6.75 6.75 8.87665 6.75 11.5C6.75 11.6689 6.7588 11.8357 6.776 12H2.75C2.05964 12 1.5 12.5597 1.5 13.25C1.5 13.9403 2.05964 14.5 2.75 14.5H7.81707C8.1347 14.8895 8.51285 15.2275 8.93717 15.5H2.75C1.50736 15.5 0.5 14.4927 0.5 13.25V2.5Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5 11.5C15.5 9.29085 13.7092 7.5 11.5 7.5C9.29085 7.5 7.5 9.29085 7.5 11.5C7.5 13.7092 9.29085 15.5 11.5 15.5C13.7092 15.5 15.5 13.7092 15.5 11.5ZM13.3915 10.5611C13.5632 10.3449 13.5273 10.0303 13.3111 9.85853C13.0949 9.68675 12.7803 9.72275 12.6085 9.93895L11.0034 11.959L10.3915 11.1889C10.2197 10.9727 9.90515 10.9367 9.68895 11.1085C9.47275 11.2803 9.43675 11.5949 9.60853 11.8111L10.6018 13.0611C10.7026 13.188 10.8527 13.2528 11.0031 13.2499C11.1538 13.2529 11.304 13.1881 11.405 13.0611L13.3915 10.5611Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1807_12212">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
