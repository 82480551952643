import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router";
import { activeConIdSet } from "../redux/userSlice";
import { useDispatch } from "react-redux";
import Button from "./button/button";

const Calendar = ({ events }: any) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  // a custom render function
  function renderEventContent(eventInfo: {
    timeText:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | null
      | undefined;
    event: {
      title:
        | string
        | number
        | boolean
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | Iterable<React.ReactNode>
        | React.ReactPortal
        | null
        | undefined;
    };
  }) {
    return (
      <Box display={"flex"} gap={1}>
        <Button variant="contained" color="primary">
          {eventInfo.event.title}
        </Button>
      </Box>
    );
  }

  return (
    <Grid
      container
      justifyContent={"center"}
      bgcolor={theme.palette.background.default}
      p={{ xs: 0, md: 1 }}
      borderRadius={5}
    >
      <Grid
        item
        xs={12}
        md={11}
        bgcolor={theme.palette.background.paper}
        borderRadius={5}
        padding={{ xs: 0, md: 1 }}
      >
        <Box
          p={1}
          sx={{
            "& .fc .fc-toolbar.fc-header-toolbar": {
              flexDirection: { xs: "column", sm: "row" },
            },
            "& .fc-daygrid-event-harness": {
              display: "flex",
              justifyContent: "center",
            },
            "& .fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link":
              {
                background: "transparent",
              },
            "& .fc-v-event .fc-event-main": {
              color: (theme) => theme.palette.primary.main,
              fontWeight: 700,
            },
            "& .fc .fc-timegrid-axis-cushion": {
              maxWidth: "max-content",
            },
          }}
        >
          <FullCalendar
            height={"75vh"}
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: isXs
                ? "timeGridDay,timeGridWeek,listMonth"
                : "timeGridDay,timeGridWeek,dayGridMonth,listMonth",
            }}
            initialView={isXs ? "listMonth" : "dayGridMonth"}
            events={events}
            selectMirror={true}
            dayMaxEvents={true}
            eventContent={renderEventContent}
            eventClick={(event) => {
              const { key } = event.event._def.extendedProps;
              navigate(`/${key}`);
              dispatch(activeConIdSet(key));
            }}
            fixedWeekCount={true}
            showNonCurrentDates={false}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Calendar;
