import * as React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import SoldDialog from "../sections/cars/sold-dialog";
import { formatNumber, removeTrailingZeros } from "../utils/utils";
import FinanceApplicableDialog from "../sections/cars/finance-applicable-dialog";
import AIMoreData from "../sections/cars/AI-more-data";
import { truncateString } from "../utils/string";
import CustomizedSwitches from "./switch/customize-switch";

export default function DataTable({ data, setData }: any) {
  const [open, setOpen] = React.useState(false);
  const [financeableOpen, setFinanceableOpen] = React.useState(false);
  const [vin, setVin] = React.useState("");
  const [financeable, setFinanceable] = React.useState("");
  const [sold, setSold] = React.useState("");
  const [guideDialog, setGuideDialog] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const guideDialogHandler = () => {
    setGuideDialog(!guideDialog);
  };

  const financeableOpenHandler = () => {
    setFinanceableOpen(!financeableOpen);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Row",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "year",
      headerName: "Year",
      // description: "This column has a value getter and is not sortable.",
      sortable: false,
      disableColumnMenu: true,
    },
    { field: "make", headerName: "Make", sortable: false, disableColumnMenu: true },
    {
      field: "model",
      headerName: "Model",
      type: "string",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "mileage",
      headerName: "Mileage",
      // description: "This column has a value getter and is not sortable.",
      sortable: false,
      type: "number",
      disableColumnMenu: true,
      renderCell: (params) => <Box>{formatNumber(parseInt(params.row?.mileage))}</Box>,
    },
    {
      field: "priceOnWebsite",
      headerName: "Price On Website",
      // description: "This column has a value getter and is not sortable.",
      sortable: false,
      type: "number",
      width: 150,
      disableColumnMenu: true,
      align: "center",
      renderCell: (params) => <Box>{removeTrailingZeros(params?.row?.priceOnWebsite)}</Box>,
    },
    {
      field: "vin",
      headerName: "VIN",
      width: 200,
      sortable: false,
    },
    {
      field: "financeApplicable",
      headerName: "Not Financeable",
      // description: "This column has a value getter and is not sortable.",
      type: "boolean",
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => (
        <CustomizedSwitches
          name="financeApplicable"
          checked={!params?.row?.financeApplicable}
          onClick={() => {
            financeableOpenHandler();
            setVin(params?.row?.vin);
            setFinanceable(params?.row?.financeApplicable);
          }}
        />
      ),
    },
    {
      field: "isCarSold",
      headerName: "Car Is Sold",
      type: "boolean",
      disableColumnMenu: true,
      renderCell: (params) => (
        <CustomizedSwitches
          name="sold"
          checked={params?.row?.isCarSold}
          onClick={() => {
            handleOpen();
            setVin(params?.row?.vin);
            setSold(params?.row?.isCarSold);
          }}
        />
      ),
    },
    {
      field: "carInfo",
      width: 400,
      headerName: "Car Info",
      type: "boolean",
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box
          onClick={() => {
            setVin(params?.row?.vin);
            guideDialogHandler();
          }}
          sx={{ cursor: "pointer" }}
          border={"1px solid black"}
          p={1}
          textAlign={"center"}
          borderRadius={1}
          width={"100%"}
          overflow={"hidden"}
        >
          <Typography variant="caption">
            {params?.row?.carInfo
              ? truncateString(params?.row?.carInfo, 10)
              : "Tell A.I. more about this car..."}
          </Typography>
        </Box>
      ),
    },
  ];

  return (
    <div style={{ height: "70vh", width: "100%" }}>
      <DataGrid
        rows={data && Object.values(data)}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 30 },
          },
        }}
        pageSizeOptions={[30]}
      />
      <SoldDialog
        open={open}
        handleClose={handleOpen}
        vin={vin}
        sold={sold}
        setData={setData}
        data={data}
      />
      <FinanceApplicableDialog
        open={financeableOpen}
        handleClose={financeableOpenHandler}
        vin={vin}
        sold={sold}
        setData={setData}
        data={data}
        financeable={financeable}
      />
      <AIMoreData open={guideDialog} handleClose={guideDialogHandler} vin={vin} />
    </div>
  );
}
