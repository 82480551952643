import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState: {
  notif: boolean;
  isOpenLead: boolean;
  AIMode: boolean;
  lastMessage: string;
  chatOpen: boolean;
  settingMenuIndex: number;
  settingMenuOpen: boolean;
  searchValue: string;
  activeConId: string;
  sortValue: string;
  hotLength: number;
  appointmentLength: number;
  contactAttemptedLength: number;
  coldLength: number;
  dealerName: string;
  allConLength: number;
  searchedKey: string;
  feedBackDialog: boolean;
  openCreateLead: boolean;
} = {
  chatOpen: false,
  notif: false,
  isOpenLead: false,
  lastMessage: "",
  AIMode: true,
  settingMenuIndex: 0,
  settingMenuOpen: false,
  searchValue: "",
  activeConId: "",
  sortValue: "All Message",
  hotLength: 0,
  appointmentLength: 0,
  contactAttemptedLength: 0,
  coldLength: 0,
  dealerName: "",
  allConLength: 0,
  searchedKey: "",
  feedBackDialog: false,
  openCreateLead: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    handleSearch: (state, { payload }) => {
      state.searchValue = payload;
    },
    allConLengthSet: (state, { payload }) => {
      state.allConLength = payload;
    },
    feedBackDialogToggled: (state) => {
      state.feedBackDialog = !state.feedBackDialog;
    },
    openCreateLeadToggled: (state) => {
      state.openCreateLead = !state.openCreateLead;
    },
    dealerNameSet: (state, { payload }) => {
      state.dealerName = payload;
    },
    hotLengthSet: (state, { payload }) => {
      state.hotLength = payload;
    },
    appointmentLengthSet: (state, { payload }) => {
      state.appointmentLength = payload;
    },
    contactAttemptedLengthSet: (state, { payload }) => {
      state.contactAttemptedLength = payload;
    },
    coldLengthSet: (state, { payload }) => {
      state.coldLength = payload;
    },
    sortValueSet: (state, { payload }) => {
      state.sortValue = payload;
    },
    activeConIdSet: (state, { payload }) => {
      state.activeConId = payload;
    },
    handleOpenSetting: (state, { payload }) => {
      state.settingMenuOpen = payload;
    },
    handleOpenChat: (state, { payload }) => {
      state.chatOpen = payload;
    },
    changeNotif: (state, { payload }) => {
      state.notif = payload;
    },
    handleOpenLead: (state, { payload }) => {
      state.isOpenLead = payload;
    },
    handleMenuIndex: (state, { payload }) => {
      state.settingMenuIndex = payload;
    },
    AImodeSet: (state, { payload }) => {
      state.AIMode = payload;
    },
    searchedKeySet: (state, { payload }) => {
      state.searchedKey = payload;
    },
  },
});

export const isNotifOpen = createSelector(
  (state: any) => state.user,
  (user: any) => user.notif
);

export const getOpenCreateLead = createSelector(
  (state: any) => state.user,
  (user: any) => user.openCreateLead
);

export const getIsOpenLead = createSelector(
  (state: any) => state.user,
  (user: any) => user.isOpenLead
);

export const userInfo = createSelector(
  (state: any) => state.user,
  (user: any) => user.userInfo
);

export const getIsOpenChat = createSelector(
  (state: any) => state.user,
  (user: any) => user.chatOpen
);

export const getAIMode = createSelector(
  (state: any) => state.user,
  (user: any) => user.AIMode
);

export const getMenuIndex = createSelector(
  (state: any) => state.user,
  (user: any) => user.settingMenuIndex
);

export const getMenuOpen = createSelector(
  (state: any) => state.user,
  (user: any) => user.settingMenuOpen
);

export const getSearchValue = createSelector(
  (state: any) => state.user,
  (user: any) => user.searchValue
);

export const getActiveConId = createSelector(
  (state: any) => state.user,
  (user: any) => user.activeConId
);

export const getSortValue = createSelector(
  (state: any) => state.user,
  (user: any) => user.sortValue
);

export const getHotLength = createSelector(
  (state: any) => state.user,
  (user: any) => user.hotLength
);

export const getAppointmentLength = createSelector(
  (state: any) => state.user,
  (user: any) => user.appointmentLength
);

export const getContactAttemptedLength = createSelector(
  (state: any) => state.user,
  (user: any) => user.contactAttemptedLength
);

export const getColdLength = createSelector(
  (state: any) => state.user,
  (user: any) => user.coldLength
);

export const getDealerName = createSelector(
  (state: any) => state.user,
  (user: any) => user.dealerName
);

export const getAllConLength = createSelector(
  (state: any) => state.user,
  (user: any) => user.allConLength
);

export const getSearchedKey = createSelector(
  (state: any) => state.user,
  (user: any) => user.searchedKey
);

export const getFeedBackDialog = createSelector(
  (state: any) => state.user,
  (user: any) => user.feedBackDialog
);

export default userSlice.reducer;

export const {
  searchedKeySet,
  changeNotif,
  AImodeSet,
  handleOpenLead,
  handleOpenSetting,
  handleOpenChat,
  handleSearch,
  handleMenuIndex,
  activeConIdSet,
  sortValueSet,
  hotLengthSet,
  appointmentLengthSet,
  contactAttemptedLengthSet,
  coldLengthSet,
  dealerNameSet,
  allConLengthSet,
  feedBackDialogToggled,
  openCreateLeadToggled,
} = userSlice.actions;
