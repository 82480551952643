import {
  IconButton,
  InputAdornment,
  FormControl as MuiFormControl,
  OutlinedInput,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchControl({ onClick, showClose = true, autoFocus, ...rest }: any) {
  const inputRef: any = useRef(null);

  useEffect(() => {
    // Focus the input field when the component mounts
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <MuiFormControl
      sx={{
        borderRadius: 2,
        m: 1,
        width: "100%",
        // maxWidth: 400,
        border: "1px solid #C6C6C6",
        height: 48,
      }}
      variant="outlined"
    >
      <OutlinedInput
        {...rest}
        inputRef={inputRef} // Attach ref to the TextField
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={onClick}>
              {showClose ? <CloseRoundedIcon /> : <SearchIcon />}
            </IconButton>
          </InputAdornment>
        }
        id="outlined-adornment-password"
        sx={{
          height: 48,
          "& fieldset": { border: "none" },
        }}
        type={"text"}
      />
    </MuiFormControl>
  );
}
