import { Button as MuiButton } from "@mui/material";
import React from "react";

export default function Button({ children, ...props }: any) {
  return (
    <MuiButton
      sx={{
        borderRadius: 3,
        boxShadow: "none",
      }}
      {...props}
    >
      {children}
    </MuiButton>
  );
}
