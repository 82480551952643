import React from "react";
import SideBarRight from "../../components/sidebar/sidebar-right";
import { useSelector } from "react-redux";
import { isNotifOpen } from "../../redux/userSlice";

export default function Notification() {
  const open = useSelector(isNotifOpen);

  return <SideBarRight open={open} />;
}
