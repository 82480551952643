import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import {
  dealerNameSet,
  getDealerName,
  getOpenCreateLead,
  openCreateLeadToggled,
} from "../redux/userSlice";
import ResetPassword from "../sections/auth/reset-password";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReportIcon from "../assets/icon/report";
import MenuIcon from "@mui/icons-material/Menu";
import { getMode, modeSet } from "../redux/layoutSlice";
import DirectionsCarRoundedIcon from "@mui/icons-material/DirectionsCarRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

import { authSet, getUid, logout } from "../redux/authSlice";
import { getData } from "../utils/request";
import CreateLead from "../sections/dashboard/create-lead";
import Messenger from "../assets/icon/messenger";
import Appointment from "../assets/icon/appointment";
import AnimationButton from "../components/button/animation-button";
import Fab from "../components/fab/fab";
import NavMenu from "../components/nav-menu";
import Feedback from "../sections/dashboard/feedback";
import { child, get } from "firebase/database";
import { dbRef, firebaseApp } from "../firebase/app";
import Dialog from "../components/dialog/dialog";
import { getAuth, signOut } from "firebase/auth";
import Button from "../components/button/button";

function ContactUs({ open }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    const auth = getAuth(firebaseApp);
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        dispatch(authSet(undefined));
        dispatch(logout());
        navigate("login");
      })
      .catch((error) => console.log(error));
  };

  const renderTitle = (
    <Typography fontSize={"20px"} fontWeight={700}>
      Contact support
    </Typography>
  );
  const renderContent = (
    <Typography align="center">
      Your account has been suspended, please contact support.
      <br /> (888) 288-8856.
    </Typography>
  );

  return (
    <Dialog
      open={open}
      handleClose={handleLogout}
      fullWidth
      renderContent={renderContent}
      renderTitle={renderTitle}
    />
  );
}

const Navbar = ({ setOpen }: any) => {
  // State
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openResetPassword, setOpenResetPassword] = React.useState(false);
  const [openFeedBackDialog, setOpenFeedBackDialog] = React.useState(false);
  const openCreateLead = useSelector(getOpenCreateLead);
  const [isActive, setIsActive] = React.useState(true);

  const theme = useTheme();
  const navigate = useNavigate();

  // Selector
  const dispatch = useDispatch();
  const mode = useSelector(getMode);
  const uid = useSelector(getUid);
  const dealerName = useSelector(getDealerName);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { pathname } = useLocation();

  React.useEffect(() => {
    const handleGetItem = () => {
      getData(`/dealers/${uid}/info/dealerName`)
        .then((r) => dispatch(dealerNameSet(r)))
        .catch((r) => console.log(r));
    };

    handleGetItem();
  }, []);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleResetPassword = () => {
    setAnchorEl(null);
    setOpenResetPassword(!openResetPassword);
  };

  const toggleDrawer = () => {
    setOpen();
  };

  const handleFeedback = () => {
    setOpenFeedBackDialog(!openFeedBackDialog);
  };

  const appointment = pathname === "/appointment";
  const report = pathname === "/report";
  const setting = pathname.includes("/setting");
  const cars = pathname === "/cars";
  const trainingRequest = pathname === "/training-request";
  const inbox = !cars && !setting && !report && !appointment && !trainingRequest;

  const getModeStatus = () => {
    getData(`dealers/${uid}/info/dashboardSettings/theme`).then((r) => dispatch(modeSet(r)));
  };

  React.useEffect(() => {
    getModeStatus();
  }, []);

  React.useEffect(() => {
    get(child(dbRef, `/dealers/${uid}/info/isActive`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setIsActive(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [uid]);

  return (
    <AppBar
      position="fixed"
      sx={{
        top: 0,
        left: 0,
        right: 0,
        px: { xs: 0, sm: 4 },
        boxShadow: "none",
        bgcolor: (theme) => theme.palette.background.paper,
        backgroundImage: "none",
        height: 75,
        borderRadius: 8,
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          display: "flex",
          justifyContent: isMobile ? "center" : "space-evenly",
          height: "100%",
        }}
      >
        {!isMobile && (
          <IconButton
            edge="start"
            color="primary"
            aria-label="open drawer"
            sx={{
              marginLeft: { lg: -2, xs: 0 },
              marginRight: { lg: 1, xs: 0 },
              borderRadius: 1,
              bgcolor: (theme) => theme.palette.secondary.main,
            }}
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
        )}

        <Link to="/">
          <img
            width={77}
            src={`/images/${mode === "dark" ? "DriveeLOGOLightVersion.webp" : "drivee-logo.svg"}`}
            alt="logo Drivee"
            style={{ cursor: "pointer", display: "flex" }}
            loading="lazy"
          />
        </Link>
        <Box
          sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
          gap={{ xs: 1, xl: 4 }}
          ml={{ xs: 1, xl: 4 }}
        >
          <Button
            variant="text"
            sx={{
              display: "flex",
              bgcolor: (theme: any) => theme.palette.secondary.main,
              color: (theme: any) =>
                inbox ? theme.palette.primary.main : theme.palette.text.primary,
              px: 2,
              fontWeight: "bold",
              fontSize: "1rem",
            }}
            startIcon={<Messenger />}
            onClick={() => navigate("/")}
          >
            Inbox
          </Button>
          <Button
            sx={{
              display: "flex",
              bgcolor: (theme: any) => theme.palette.secondary.main,
              color: (theme: any) =>
                appointment ? theme.palette.primary.main : theme.palette.text.primary,
              fontSize: "1rem",
              fontWeight: "bold",
              px: 2,
            }}
            onClick={() => navigate("/appointment")}
            startIcon={<Appointment />}
          >
            Appointment
          </Button>
          <Button
            sx={{
              display: "flex",
              bgcolor: (theme: any) => theme.palette.secondary.main,
              color: (theme: any) =>
                report ? theme.palette.primary.main : theme.palette.text.primary,
              fontSize: "1rem",
              fontWeight: "bold",
              px: 2,
            }}
            startIcon={<ReportIcon />}
            onClick={() => navigate("/report")}
          >
            Report
          </Button>
          <Button
            sx={{
              display: "flex",
              bgcolor: (theme: any) => theme.palette.secondary.main,
              color: (theme: any) =>
                cars ? theme.palette.primary.main : theme.palette.text.primary,
              fontSize: "1rem",
              fontWeight: "bold",
              px: 2,
            }}
            startIcon={<DirectionsCarRoundedIcon />}
            onClick={() => navigate("/cars")}
          >
            Inventory
          </Button>
        </Box>
        <Box
          gap={1}
          sx={{
            flexGrow: 0,
            display: "flex",
            alignItems: "center",
          }}
        >
          <AnimationButton title={"Feedback"} onClick={handleFeedback} />
          {!isMobile && (
            <Button
              variant={"contained"}
              onClick={() => dispatch(openCreateLeadToggled())}
              sx={{
                bgcolor: theme.palette.primary.main,
                fontSize: 14,
                fontWeight: 700,
                py: "12px",
                boxShadow: "none",
              }}
              startIcon={<AddRoundedIcon />}
            >
              Create Lead
            </Button>
          )}
          <Box
            bgcolor={isMobile ? "" : mode === "dark" ? "#313645" : "#F8F8F8"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={1}
            p={0.5}
            borderRadius={3}
          >
            {!isMobile && dealerName !== "" && (
              <Box display={"flex"}>
                <Box width={"100%"} display={"flex"} alignItems={"end"} flexDirection={"column"}>
                  <Typography
                    noWrap
                    variant="body2"
                    color={(theme) => theme.palette.text.primary}
                    fontWeight={700}
                  >
                    {dealerName}
                  </Typography>
                </Box>
              </Box>
            )}
            {!isMobile && <Fab onClick={handleOpenUserMenu} icon={<PersonIcon />} />}
          </Box>
        </Box>
      </Toolbar>
      <NavMenu anchorEl={anchorEl} handleClose={handleCloseMenu} />
      <ResetPassword open={openResetPassword} handleClose={handleResetPassword} />
      <CreateLead open={openCreateLead} handleClose={() => dispatch(openCreateLeadToggled())} />
      <Feedback open={openFeedBackDialog} handleClose={handleFeedback} />
      <ContactUs open={!isActive} />
    </AppBar>
  );
};

export default Navbar;
