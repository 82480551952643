import React from "react";

import { Box as MUBox } from "@mui/material";

export default function ControlledBox({ children }: any) {
  return (
    <MUBox
      bgcolor={(theme) => theme.palette.background.paper}
      py={2}
      px={2}
      borderRadius={4}
      width={"100%"}
    >
      {children}
    </MUBox>
  );
}
