import React, { useState } from "react";
import { getDataBy } from "../utils/request";
import { useSelector } from "react-redux";
import { getUid } from "../redux/authSlice";
import { Box, CircularProgress, Typography } from "@mui/material";
import DataTable from "../components/new-table";
import SearchControl from "../components/search-control/search-control";
import Button from "../components/button/button";

export default function Cars() {
  const [loading, setLoading] = useState(true);
  const uid = useSelector(getUid);
  const [carsData, setCarsData] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState("");
  const [soldCar, setSoldCar] = useState(false);

  const handleGetItem = (status: boolean) => {
    getDataBy(`/cars/${uid}`, "isCarSold", status).then((r: any) => {
      // Get the entries of the object (key-value pairs)
      const entries = Object.entries(r);

      // Add the key and a new 'quantity' field to each item
      const updatedItems: any = entries
        .sort((a: any, b: any) => {
          if (a[1].isCarSold === b[1].isCarSold) {
            return 0;
          }
          return a[1].isCarSold ? 1 : -1;
        })
        .map(([key, item]: any, id) => {
          return { ...item, vin: key, id: id + 1 };
        });

      setCarsData(updatedItems);
      setLoading(false);
    });
  };

  React.useEffect(() => {
    handleGetItem(false);
  }, []);

  const handleReset = () => {
    setFiltered([]);
    setSearch("");
  };

  const handleSearchValue = (e: any) => {
    const value = e.target.value;
    setSearch(value);
    const filteredList = Object.values(carsData).filter((c: any) =>
      c?.vin?.toLowerCase().includes(value?.trim()?.toLowerCase())
    );
    setFiltered(filteredList);
    if (value === "") handleReset();
  };

  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.background.paper,
        borderRadius: 3,
      }}
    >
      <Box display={"flex"} justifyContent={"space-between"} p={1} flexWrap={"wrap"}>
        <Box>
          <Typography variant="h5" fontWeight={600}>
            Inventory
          </Typography>
        </Box>
        <Box display={"flex"} flex={1} justifyContent={"center"}>
          <Box
            display={"flex"}
            borderRadius={1}
            gap={1}
            p={1}
            bgcolor={(theme) => theme.palette.background.default}
          >
            <Button
              variant={soldCar ? "contained" : "outlined"}
              onClick={() => {
                setSoldCar(true);
                handleGetItem(true);
              }}
            >
              Sold Cars
            </Button>
            <Button
              variant={!soldCar ? "contained" : "outlined"}
              color="primary"
              onClick={() => {
                setSoldCar(false);
                handleGetItem(false);
              }}
            >
              Available Cars
            </Button>
          </Box>
        </Box>
      </Box>

      {loading ? (
        <Box display={"flex"} justifyContent={"center"}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box width={400}>
            <SearchControl
              placeholder={"Search by VIN..."}
              onChange={handleSearchValue}
              onClick={handleReset}
              showClose={search !== ""}
              value={search}
            />
          </Box>
          <Box p={1}>
            <DataTable data={(search !== "" && filtered) || carsData} setData={setCarsData} />
          </Box>
        </>
      )}
    </Box>
  );
}
