import { Box, SelectChangeEvent, Typography } from "@mui/material";
import React from "react";
import Selector from "../../components/select/select";
import CustomizedSwitches from "../../components/switch/customize-switch";
import { convertTo24Hour } from "../../utils/utils";
import Button from "../../components/button/button";
import ControlledBox from "../../components/box/controlled-box";

const clockData = [
  "0:00",
  "0:30",
  "1:00",
  "1:30",
  "2:00",
  "2:30",
  "3:00",
  "3:30",
  "4:00",
  "4:30",
  "5:00",
  "5:30",
  "6:00",
  "6:30",
  "7:00",
  "7:30",
  "8:00",
  "8:30",
  "9:00",
  "9:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
];

export default function BusinessHours() {
  const [week, setWeek] = React.useState([
    { day: "Monday", start: "8:00", end: "5:00", morning: "am", midnight: "pm", disabled: false },
    { day: "Tuesday", start: "8:00", end: "5:00", morning: "am", midnight: "pm", disabled: false },
    {
      day: "Wednesday",
      start: "8:00",
      end: "5:00",
      morning: "am",
      midnight: "pm",
      disabled: false,
    },
    { day: "Thursday", start: "8:00", end: "5:00", morning: "am", midnight: "pm", disabled: false },
    { day: "Friday", start: "8:00", end: "5:00", morning: "am", midnight: "pm", disabled: false },
    { day: "Saturday", start: "8:00", end: "5:00", morning: "am", midnight: "pm", disabled: false },
    { day: "Sunday", start: "8:00", end: "5:00", morning: "am", midnight: "pm", disabled: false },
  ]);

  const handleChangeDay = (day: string, updates: object) => {
    setWeek((prevWeek) =>
      prevWeek.map((item) => (item.day === day ? { ...item, ...updates } : item))
    );
  };

  const handleChange = (day: string) => {
    setWeek((prevWeek) =>
      prevWeek.map((item) => (item.day === day ? { ...item, disabled: !item.disabled } : item))
    );
  };

  return (
    <>
      <ControlledBox>
        <Typography variant="h6" fontWeight={700}>
          Hours of Operations
        </Typography>
        {week.map((w, id) => (
          <Box key={id} display={"flex"}>
            <Box
              width={113}
              height={80}
              border={"1px solid #E3E3E3"}
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              gap={1}
              borderRadius={1}
              p={1}
              my={1}
            >
              <Typography display={"flex"} variant="body2" color={"#7D7F80"}>
                {w.day}
              </Typography>
              <Typography display={"flex"} variant="body2" color={"#7D7F80"}>
                {convertTo24Hour(w.start, w.morning)} - {convertTo24Hour(w.end, w.midnight)}
              </Typography>
            </Box>
            <Box ml={1}>
              <Typography>From</Typography>
              <Box display={"flex"} gap={1}>
                <Selector
                  disabled={w.disabled}
                  data={clockData}
                  width={85}
                  value={w.start}
                  onChange={(e: SelectChangeEvent) =>
                    handleChangeDay(w.day, {
                      start: e.target.value,
                      morning: w.morning,
                      end: w.end,
                      midnight: w.midnight,
                    })
                  }
                />
                <Selector
                  data={["am", "pm"]}
                  disabled={w.disabled}
                  width={85}
                  value={w.morning}
                  onChange={(e: SelectChangeEvent) =>
                    handleChangeDay(w.day, {
                      start: w.start,
                      morning: e.target.value,
                      end: w.end,
                      midnight: w.midnight,
                    })
                  }
                />
              </Box>
            </Box>
            <Box ml={2}>
              <Typography>To</Typography>
              <Box display={"flex"} gap={1}>
                <Selector
                  data={clockData}
                  width={85}
                  value={w.end}
                  disabled={w.disabled}
                  onChange={(e: SelectChangeEvent) =>
                    handleChangeDay(w.day, {
                      start: w.start,
                      morning: w.morning,
                      end: e.target.value,
                      midnight: w.midnight,
                    })
                  }
                />
                <Selector
                  data={["am", "pm"]}
                  width={85}
                  value={w.midnight}
                  disabled={w.disabled}
                  onChange={(e: SelectChangeEvent) =>
                    handleChangeDay(w.day, {
                      start: w.start,
                      morning: w.morning,
                      end: w.end,
                      midnight: e.target.value,
                    })
                  }
                />
              </Box>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} mx={1} alignItems={"center"}>
              <Box mr={1}>
                <Typography variant="body2" fontWeight={"bold"}>
                  Appointment Only
                </Typography>
              </Box>
              <CustomizedSwitches name="AIMode" />
            </Box>
            <Box display={"flex"} justifyContent={"space-between"} mx={1} alignItems={"center"}>
              <Box mr={1}>
                <Typography variant="body2" fontWeight={"bold"}>
                  Closed
                </Typography>
              </Box>
              <CustomizedSwitches
                onChange={() => handleChange(w.day)}
                checked={w.disabled}
                name="AIMode"
              />
            </Box>
          </Box>
        ))}
      </ControlledBox>
      <Box display={"flex"} justifyContent={"left"} width={"100%"} gap={1}>
        <Button variant="outlined" color="primary">
          Cancel
        </Button>
        <Button variant="contained" color="primary">
          Save Change
        </Button>
      </Box>
    </>
  );
}
