import { SvgIcon } from "@mui/material";
import React from "react";

export default function LineIcon({ fill, ...props }: any) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" width="4" height="80" viewBox="0 0 4 80" {...props}>
      <rect width="4" height="80" rx="2" fill={fill} />
    </SvgIcon>
  );
}
