import React, { useEffect } from "react";
import { Grid, Stack } from "@mui/material";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AImodeSet, getActiveConId, getAIMode, getIsOpenChat } from "../../redux/userSlice";
import Dialog from "../../components/dialog/dialog";
import { onValue, query, ref, update } from "firebase/database";
import { getUid } from "../../redux/authSlice";
import { firebaseDb } from "../../firebase/app";
import Snackbar from "../../components/snackbar/snackbar";
import LeadInfo from "./lead-info";
import AISetting from "./AI-setting";
import RenderCarInfo from "./car-info";

const infiniteData = {
  email: "",
  phoneNumber: "",
  year: "",
  make: "",
  model: "",
  priceOnWebsite: "",
  VINNumber: "",
  mileage: "",
};
export default function ChatInfo({
  correctUser,
  setAnchorSEl,
  handleUpdateLead,
  handleOpenBug,
  openAISetting,
  setOpenAISetting,
  openLeadInfo,
  setOpenLeadInfo,
  openCarInfo,
  setOpenCarInfo,
}: any) {
  // State
  const [isFollow, setISFollow] = useState(true);
  const [lead, setLead] = useState(infiniteData);
  const [message, setMessage] = useState({ message: "", open: false, type: "" });

  // Selector
  const activeConId = useSelector(getActiveConId);
  const isOpenChat = useSelector(getIsOpenChat);
  const uid = useSelector(getUid);
  const AIMode = useSelector(getAIMode);

  const dispatch = useDispatch();

  const getLead = () => {
    const currentRef = query(ref(firebaseDb, `dealers/${uid}/leads/${activeConId}`));
    onValue(
      currentRef,
      (snapshot) => {
        const data = snapshot.val();
        setLead(data);
      },
      { onlyOnce: true }
    );
  };

  const handleOpenStatusMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorSEl(event.currentTarget);

  function handleChange(e: any) {
    const { name, checked } = e.target;
    const currentRef = ref(firebaseDb, `conversations/${uid}/${activeConId}`);
    if (name === "AIMode") {
      dispatch(AImodeSet(checked));
      update(currentRef, { AIMode: checked });
    }

    if (name === "followUp") {
      update(currentRef, { followUpEnabled: checked });
      setISFollow(checked);
    }
  }

  useEffect(() => {
    dispatch(AImodeSet(correctUser?.AIMode));
    setISFollow(correctUser?.followUpEnabled || false);
  }, [correctUser]);

  useEffect(() => {
    if (activeConId) getLead();
  }, [activeConId]);

  const renderLeadInfo = (
    <LeadInfo
      handleOpenStatusMenu={handleOpenStatusMenu}
      handleOpenBug={handleOpenBug}
      correctUser={correctUser}
      handleUpdateLead={handleUpdateLead}
      lead={lead}
      setMessage={setMessage}
    />
  );

  const renderCarInformation = <RenderCarInfo lead={lead} setMessage={setMessage} />;

  const renderAISetting = (
    <AISetting AIMode={AIMode} handleChange={handleChange} isFollow={isFollow} />
  );

  const desktopSize = (
    <Grid
      item
      lg={isOpenChat ? 3 : false}
      display={"flex"}
      height={"100%"}
      width={"100%"}
      sx={{
        display: { xs: "none", lg: isOpenChat ? "flex" : "none" },
      }}
    >
      <Stack
        display={"flex"}
        flexDirection={"column"}
        sx={{
          gap: { xs: 0, xl: 1 },
          overflowY: "auto",
          height: "100%",
          width: "100%",
        }}
      >
        {renderLeadInfo}
        {renderAISetting}
        {renderCarInformation}
      </Stack>
    </Grid>
  );

  const renderDialogAISetting = (
    <Dialog
      open={openAISetting}
      fullWidth={true}
      handleClose={() => setOpenAISetting(false)}
      renderContent={renderAISetting}
      renderTitle={"A.I. Setting"}
    />
  );

  const renderDialogLeadInfo = (
    <Dialog
      open={openLeadInfo}
      fullWidth={true}
      handleClose={() => setOpenLeadInfo(false)}
      renderContent={renderLeadInfo}
      renderTitle={"Lead Information"}
    />
  );

  const renderDialogCarInfo = (
    <Dialog
      open={openCarInfo}
      fullWidth={true}
      handleClose={() => setOpenCarInfo(false)}
      renderContent={renderCarInformation}
      renderTitle={"Car Information"}
    />
  );

  return (
    <>
      {desktopSize}
      {renderDialogAISetting}
      {renderDialogLeadInfo}
      {renderDialogCarInfo}
      <Snackbar
        handleClose={() => setMessage({ message: "", open: false, type: "" })}
        message={message.message}
        open={message.open}
        type={message.type}
      />
    </>
  );
}
