import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Radio from "@mui/material/Radio";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { getToken, getUid } from "../../redux/authSlice";
import { getData } from "../../utils/request";
import ControlledInput from "../../components/input/controlled-input";
import Button from "../../components/button/button";
import ControlledBox from "../../components/box/controlled-box";

type SettingType = {
  lastAppThreshold: number | any;
  firstMessage: string;
  dealerName: string;
  dealerPhone: string;
  dealerEmail: string;
};

export default function Dealership() {
  const [state, setState]: any = useState({
    lastAppThreshold: 0,
    firstMessage: "",
    dealerName: "",
    dealerPhone: "",
    dealerEmail: "",
    notifs: [
      {
        name: "John Doe",
        email: "johndoe@example.com",
        role: "owner",
        callback_request: true,
        appointments: true,
        hot_leads: true,
        first_responder: true,
        dealer_assistant_request: true,
      },
    ],
  });

  const token = useSelector(getToken);
  const uid = useSelector(getUid);

  const handleChange = ({ currentTarget: input }: any) => {
    const value = input.value;
    setState({ ...state, [input.name]: value });
  };

  const handleGetData = (data: SettingType) => {
    const { lastAppThreshold, firstMessage, dealerName, dealerPhone, dealerEmail } = data;
    setState({
      dealerName: dealerName,
      dealerPhone: dealerPhone,
      dealerEmail: dealerEmail,
      lastAppThreshold: lastAppThreshold,
      firstMessage: firstMessage,
    });
  };

  useEffect(() => {
    getData(`dealers/${uid}/info`).then((r: any) => handleGetData(r));
  }, []);

  const postData = () => {
    postDealershipInfo();
    postNotificationInfo();
  };

  const postDealershipInfo = async () => {
    // Create an Axios instance with a base URL
    const apiClient = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL, // Replace with your base URL
      headers: {
        Authorization: ` ${token}`, // Replace with your actual token
        "Content-Type": "application/json",
      },
    });

    const data = {
      section: "user_info",
      data: {
        name: state.dealerName,
        email: state.dealerEmail,
        phone_number: state.dealerPhone,
      },
    };
    try {
      const response = await apiClient.post("", data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const postNotificationInfo = async () => {
    // Create an Axios instance with a base URL
    const apiClient = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL, // Replace with your base URL
      headers: {
        Authorization: ` ${token}`, // Replace with your actual token
        "Content-Type": "application/json",
      },
    });

    const data = {
      section: "notification_data",
      data: {
        notifs: state.notifs,
      },
    };
    try {
      const response = await apiClient.post("", data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ControlledBox>
        <Typography variant="h6" gutterBottom color={"#505050"}>
          Dealership Information
        </Typography>
        <Box display={"flex"} gap={2}>
          <ControlledInput
            placeholder="Enter Your Name"
            value={state.dealerName}
            label="Dealership name"
            name="dealerName"
            onChange={handleChange}
          />
          <ControlledInput
            placeholder="Enter Your phone"
            label="Dealership main phone number"
            value={state.dealerPhone}
            onChange={handleChange}
            type={"number"}
            name="dealerPhone"
          />
          <ControlledInput
            placeholder="Enter Your Email"
            label="Dealership email"
            value={state.dealerEmail}
            onChange={handleChange}
            name="dealerEmail"
          />
        </Box>
      </ControlledBox>

      <ControlledBox>
        <Typography variant="h6" gutterBottom color={"#505050"}>
          Notification
        </Typography>
        <Typography variant="body1" gutterBottom color="#9B9B9B">
          Please add information of the people who you would like to receive notifications through
          text
        </Typography>

        <Grid container justifyContent={"left"} spacing={3}>
          <Grid item md={4} xs={12}>
            <ControlledInput
              placeholder="Name"
              value={state.dealerName}
              label="Name"
              name="dealerName"
              onChange={handleChange}
            />
            <Box my={1}>
              <ControlledInput
                placeholder="phone number"
                value={state.dealerName}
                label="phone number"
                name="dealerName"
                onChange={handleChange}
              />
            </Box>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label" sx={{ color: "#2F2F2F" }}>
                Role
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
                sx={{ flexDirection: "row" }}
              >
                <FormControlLabel value="owner" control={<Radio />} label="Owner" />
                <FormControlLabel value="staff" control={<Radio />} label="Staff" />
              </RadioGroup>
            </FormControl>
            <Box display={"flex"}>
              <Button variant="outlined" startIcon={<AddIcon />}>
                Add One More Person
              </Button>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box display={"flex"} alignItems={"baseline"} flexDirection={"column"}>
              <Typography color={"#505050"} variant="body1">
                Notify Option
              </Typography>
              <FormControlLabel
                control={<Checkbox sx={{ pl: 0 }} />}
                label="Callback Request"
                labelPlacement="end"
              />
              <FormControlLabel
                control={<Checkbox sx={{ pl: 0 }} />}
                label="Appointments"
                labelPlacement="end"
              />
              <FormControlLabel
                control={<Checkbox sx={{ pl: 0 }} />}
                label="Hot Leads"
                labelPlacement="end"
              />
              <FormControlLabel
                control={<Checkbox sx={{ pl: 0 }} />}
                label="Dealer Assistant Request"
                labelPlacement="end"
              />
            </Box>
          </Grid>
        </Grid>
      </ControlledBox>
      <Box display={"flex"} justifyContent={"left"} width={"100%"} gap={1}>
        <Button variant="outlined" color="primary">
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={postData}>
          Save Change
        </Button>
      </Box>
    </>
  );
}
