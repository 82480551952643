import { Backdrop, Box, IconButton, Typography } from "@mui/material";

import React, { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";

import ImageIcon from "@mui/icons-material/Image";

import { formatDateFrom } from "../../utils/time-convert";
import CloseIcon from "@mui/icons-material/Close";
import { handleDownloadFromGcs } from "../../utils/gcs";
import MessageWithClickableLink from "../../utils/message-with-clickable-link";
import { getMode } from "../../redux/layoutSlice";
import { useSelector } from "react-redux";

type PropTypes = {
  currentUser: any;
  value: any;
};

const ChatItem = ({ value }: PropTypes) => {
  const [openImage, setOpenImage] = useState(false);
  const [src, setSrc] = useState("");
  const mode = useSelector(getMode);

  const handleClose = () => {
    setOpenImage(false);
  };

  const handleOpen = () => {
    setOpenImage(true);
  };

  const handleDownload = (path: string) => {
    // TODO fix urls
    const urls = path.split(",");
    const url = urls[0];
    handleDownloadFromGcs(url)
      .then((src: any) => setSrc(src))
      .catch((e: any) => console.log(e));
  };

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        width={"100%"}
        height={"fit-content"}
        justifyContent={value?.receive ? "start" : "end"}
        p={1}
      >
        {!value?.MMS && (
          <Box display={"flex"} gap={1} overflow={"hidden"}>
            <Box
              sx={{
                width: "fit-content",
                display: { xs: "grid", sm: "grid" },
              }}
              height={"fit-content"}
              justifyItems={value?.receive ? "start" : "end"}
              textAlign={"start"}
            >
              <Box
                display={"flex"}
                bgcolor={(theme) =>
                  value?.receive
                    ? mode === "dark"
                      ? "#383838"
                      : "#CEEBFB"
                    : theme.palette.primary.main
                }
                mx={1}
                sx={{
                  maxWidth: 500,
                  borderRadius: value?.receive ? "10px 10px 10px 0" : "10px 10px 0 10px",
                  position: "relative",
                  ...(value?.receive && {
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      width: 0,
                      height: 0,
                      right: "auto",
                      top: "auto",
                      left: -4,
                      bottom: -10,
                      border: "10px solid",
                      transform: "rotate(45deg)",
                      borderColor: () =>
                        `transparent transparent transparent ${mode === "dark" ? "#383838" : "#CEEBFB"}`,
                    },
                  }),

                  ...(!value?.receive && {
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      width: 0,
                      height: 0,
                      left: "auto",
                      top: "auto",
                      right: -4,
                      bottom: -10,
                      border: "10px solid",
                      transform: "rotate(135deg)",
                      borderColor: (theme) =>
                        `transparent transparent transparent ${theme.palette.primary.main}`,
                    },
                  }),
                }}
              >
                {value?.content && (
                  <Typography
                    display={"flex"}
                    sx={{
                      wordBreak: "break-word",
                      maxWidth: 500,
                      borderRadius: 50,
                    }}
                    px={1}
                    variant="body2"
                    color={value?.receive ? "" : "white"}
                  >
                    <MessageWithClickableLink messageContent={value?.content} />
                  </Typography>
                )}
              </Box>

              <Typography
                color={"#8F92A1"}
                variant="caption"
                sx={{
                  display: "flex",
                  justifyContent: !value?.receive ? "end" : "start",
                }}
              >
                {formatDateFrom(value?.timestamp)}
                {value?.receive ? "" : `${value?.sender === "AI" ? " - AI" : " - Dealer"}`}
                {value?.status === -1 && (
                  <ErrorIcon
                    sx={{
                      color: "red",
                      width: "15px",
                    }}
                  />
                )}
              </Typography>
            </Box>
          </Box>
        )}
        {value?.MMS && (
          <Box
            display={"flex"}
            width={{
              xl: 400,
              lg: 350,
              md: 300,
              sm: 250,
              xs: 200,
            }}
            height={{
              xl: 350,
              lg: 300,
              md: 250,
              sm: 200,
              xs: 150,
            }}
            borderRadius={2}
            border={"1px solid #e3fee0"}
            sx={{
              cursor: "pointer",
              backgroundColor: "lightblue",
            }}
          >
            <Box display={"flex"} justifyContent={"center"} alignContent={"center"} width={"100%"}>
              <IconButton
                size="large"
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={() => {
                  handleDownload(value?.mediaURL);
                  handleOpen();
                }}
              >
                <ImageIcon />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openImage}
        onClick={handleClose}
      >
        <Box position={"absolute"} right={"1rem"} top={"1rem"}>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>
        {openImage && (
          <img
            alt={src}
            loading="lazy"
            style={{
              maxHeight: window.innerHeight,
              maxWidth: window.innerWidth,
              width: "100%",
              height: "auto",
              objectFit: "contain",
            }}
            src={src}
          />
        )}
      </Backdrop>
    </>
  );
};

export default ChatItem;
