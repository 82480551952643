import { FormControl, Input as MuiInput, Typography } from "@mui/material";

import React, { memo } from "react";

function ControlledInput({ error, fullWidth = true, formControlRef, label, sx, ...rest }: any) {
  return (
    <FormControl fullWidth={fullWidth} ref={formControlRef}>
      {label && (
        <Typography
          color={error ? "red" : "#505050"}
          fontSize={13}
          fontWeight={700}
          mb={1}
          variant="caption"
        >
          {label}
        </Typography>
      )}
      <MuiInput
        variant="outlined"
        sx={{
          background: (theme) => theme.palette.background.paper,
          borderRadius: 2,
          fontSize: "small",
          // height: (theme: any) => (chat ? theme.spacing(7) : theme.spacing(5)),
          padding: (theme) => theme.spacing(1, 2),
          border: "1px solid #AFAFAF",
          ...sx, // Merge the sx prop here
        }}
        disableUnderline
        {...rest}
      />
      {error && <Typography fontSize={"small"}>{error}</Typography>}
    </FormControl>
  );
}

export default memo(ControlledInput);
