import React from "react";

import Router from "../router/router";

export default function Layout() {
  return <Router />;
}

// const tkn = urlParam('tkn');
// const auth = getAuth();
// const navigate = useNavigate();
// function handleNavigate(to: To) {
//   navigate(to);
// }
// useEffect(() => {
//   if (!tkn || tkn === 'tkn') return;
//   const handleLogin = () => {
//     signInWithCustomToken(auth, tkn as string)
//       .then(() => {
//         handleNavigate('/');
//       })
//       .catch((error) => {
//         console.log('@handleLogin', error);
//       });
//   };
//   handleLogin();
// }, [tkn]);
