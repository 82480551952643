import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { changeNotif } from "../../redux/userSlice";
import { Divider, IconButton, Typography } from "@mui/material";
import { onValue, query, ref, update } from "firebase/database";
import { firebaseDb } from "../../firebase/app";
import { getUid } from "../../redux/authSlice";
import { truncateString } from "../../utils/string";

const TemporaryDrawer = ({ open }: any) => {
  const dispatch = useDispatch();
  const uid = useSelector(getUid);
  const [notifData, setNotifData] = React.useState<any>(null);

  function getData() {
    const databaseRef = query(ref(firebaseDb, `conversations/${uid}`));
    onValue(databaseRef, async (snapshot) => {
      const data = snapshot.val();

      if (data) {
        const dataWithPushIds = Object.keys(data).map((pushId) => ({
          pushId,
          ...data[pushId],
        }));
        setNotifData(dataWithPushIds.filter((c) => c?.lastMessage?.timestamp && !c.read));
      }
    });
  }

  async function changeReadConversation(conId: string, messageID: string) {
    try {
      if (!uid || !conId || !messageID) {
        console.log("update read error: Missing required parameters");
        return;
      }

      const conversationRef = ref(firebaseDb, `conversations/${uid}/${conId}`);

      await update(conversationRef, { read: true });
    } catch (error) {
      console.error("Error updating message:", error);
    }
  }

  function handleClose() {
    dispatch(changeNotif(false));
  }

  React.useEffect(() => {
    getData();
  }, [dispatch]);

  const list = (
    <Box
      sx={{
        width: 300,
        overflow: "hidden",
        position: "relative",
        overflowY: "scroll",
      }}
      role="presentation"
    >
      <Box position={"absolute"} top={5} left={5}>
        <IconButton onClick={() => dispatch(changeNotif(false))} sx={{ color: "black" }}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </Box>
      <List
        sx={{
          padding: "1rem",
          marginTop: "2.5rem",
        }}
      >
        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <Typography fontSize={"20px"} fontWeight={700}>
            Notification
          </Typography>
        </Box>
        {notifData && notifData?.length > 0 ? (
          notifData
            .filter((user: any) => !user.read)
            .sort((a: any, b: any) => b?.startChatTs - a?.startChatTs)
            .map((user: any, index: number) => (
              <Box key={index}>
                <ListItem
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "100%",
                    gap: "15px",
                  }}
                >
                  {/* <Box
                  position={'relative'}
                  height={'40px'}
                  width={'40px'}
                  borderRadius={'10px'}
                  sx={{ background: user.backgroundGradient }}
                /> */}
                  <Box display={"grid"}>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      width={"100%"}
                      color={"#8F92A1"}
                      fontSize={"14px"}
                    >
                      <Typography fontSize={"14px"}>
                        form: {user.companyName || "carfax"}
                      </Typography>
                      {user.timestamp}
                    </Box>
                    <Typography
                      color={!user.read ? "black" : "#8F92A1"}
                      fontWeight={600}
                      fontSize={"15px"}
                    >
                      {user.name}
                    </Typography>
                    <Typography fontSize={"14px"}>
                      {truncateString(user.lastMessage?.content, 30)}
                    </Typography>
                    {false && (
                      <Typography
                        color={"#07A4FC"}
                        fontSize={"13px"}
                        fontWeight={600}
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          changeReadConversation(user?.pushId, user?.lastMessage?.messageID)
                        }
                      >
                        Mark as read
                      </Typography>
                    )}
                  </Box>
                </ListItem>
                <Divider />
              </Box>
            ))
        ) : (
          <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} height={500}>
            <Box textAlign={"center"}>
              <img
                src="/images/noMessage.png"
                alt="no message"
                width={"fit-content"}
                style={{ marginInline: "auto" }}
                loading="lazy"
              />
              <Typography fontWeight={600}>You have no unread messages</Typography>
            </Box>
          </Box>
        )}
      </List>
    </Box>
  );

  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      {list}
    </Drawer>
  );
};

export default TemporaryDrawer;
