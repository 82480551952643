import { getDownloadURL, ref } from "firebase/storage";

import { storage } from "../firebase/app";

const handleDownloadFromGcs = async (path: string) => {
  return await new Promise((resolve, reject) => {
    getDownloadURL(ref(storage, path))
      .then((url: any) => resolve(url))
      .catch((e) => reject(e));
  });
};

export { handleDownloadFromGcs };
