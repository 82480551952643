import { Box } from "@mui/material";
import React from "react";

export default function CustomizeBox({ children }: any) {
  return (
    <Box borderRadius={2} p={1} display={"grid"} width={"100%"}>
      {children}
    </Box>
  );
}
