import { Box, OutlinedInput } from "@mui/material";
import React, { useState } from "react";
import { firebaseDb } from "../../firebase/app";
import { ref, update } from "firebase/database";
import { getUid } from "../../redux/authSlice";
import { useSelector } from "react-redux";
import { getActiveConId } from "../../redux/userSlice";
import Dialog from "../../components/dialog/dialog";
import Snackbar from "../../components/snackbar/snackbar";
import { getData } from "../../utils/request";
import Button from "../../components/button/button";

type PropTypes = {
  open: boolean;
  handleClose: any;
  setCurrentUser: any;
};
const infiniteData = {
  firstName: "",
  lastName: "",
};

const EditLeadName = ({ open, handleClose, setCurrentUser }: PropTypes) => {
  const uid = useSelector(getUid);
  const activeConId = useSelector(getActiveConId);
  const [state, setState] = useState(infiniteData);
  const [message, setMessage] = useState({ message: "", open: false, type: "" });

  const updateUserName = () => {
    const name = { firstName: state.firstName, lastName: state.lastName };
    const currentUserRef = ref(firebaseDb, `conversations/${uid}/${activeConId}`);
    update(currentUserRef, name).then(() => {
      setMessage({ message: "done", open: true, type: "" });
      setCurrentUser({ firstName: state.firstName, lastName: state.lastName });
    });
  };

  const updateUser = () => {
    const currentUserRef = ref(firebaseDb, `dealers/${uid}/leads/${activeConId}`);
    update(currentUserRef, {
      firstName: state.firstName,
      lastName: state.lastName,
    }).catch(() => {
      setMessage({ message: "smt wen wrong", open: true, type: "error" });
    });
  };

  const handleSave = () => {
    updateUserName();
    updateUser();
    handleClose();
    setState(infiniteData);
  };

  const handleChange = (e: any) => {
    const { value, name } = e.target;
    setState({ ...state, [name]: value });
  };

  const getModeStatus = () => {
    getData(`dealers/${uid}/leads/${activeConId}`).then((r: any) => {
      setState({ firstName: r.firstName, lastName: r.lastName });
    });
  };

  React.useEffect(() => {
    if (open) getModeStatus();
  }, [open, activeConId]);

  const renderTitle = "Edit Lead Name";

  const renderContent = (
    <Box gap={1} display={"flex"} width={"100%"}>
      <OutlinedInput
        sx={{ width: "100%" }}
        onChange={handleChange}
        value={state.firstName}
        name="firstName"
        id="firstName"
        placeholder="Enter Lead firstName"
        type="text"
      />
      <OutlinedInput
        sx={{ width: "100%" }}
        onChange={handleChange}
        value={state.lastName}
        name="lastName"
        id="lastName"
        placeholder="Enter Lead lastName"
        type="text"
      />
    </Box>
  );

  const renderAction = (
    <Button variant="contained" color="primary" onClick={handleSave} sx={{ width: "100%" }}>
      Submit
    </Button>
  );

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        handleClose={handleClose}
        renderTitle={renderTitle}
        renderContent={renderContent}
        renderActions={renderAction}
      />
      <Snackbar
        handleClose={() => setMessage({ message: "", open: false, type: "" })}
        message={message.message}
        open={message.open}
        type={message.type}
      />
    </>
  );
};
export default EditLeadName;
