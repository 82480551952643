import {
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ConversationList from "./conversation-list";
import ChatList from "./chat-list";
import ChatInfo from "./chat-info";
import Notifications from "./notifications";
import { firebaseDb } from "../../firebase/app";
import { ref, update } from "firebase/database";
import { getUid } from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  activeConIdSet,
  getActiveConId,
  getSearchedKey,
  handleOpenChat,
} from "../../redux/userSlice";
import LocalFireDepartmentOutlinedIcon from "@mui/icons-material/LocalFireDepartmentOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined";
import Menu from "../../components/menu/menu";
import BugReportRoundedIcon from "@mui/icons-material/BugReportRounded";
import EditLeadName from "./edit-lead-name";
import BorderColorRoundedIcon from "@mui/icons-material/BorderColorRounded";
import BugDialog from "./bug-dialog";
import { getData } from "../../utils/request";

export default function Inbox() {
  const [openInfo, setOpenInfo] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>();
  const [openStatus, setOpenStatus] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorSEl, setAnchorSEl] = React.useState<null | HTMLElement>(null);
  const [openBug, setOpenBug] = useState(false);
  const [leadUpdateDialog, setLeadUpdateDialog] = useState(false);
  const dispatch = useDispatch();
  const [openAISetting, setOpenAISetting] = useState(false);
  const [openLeadInfo, setOpenLeadInfo] = useState(false);
  const [openCarInfo, setOpenCarInfo] = useState(false);
  const [openReportIssue, setOpenReportIssue] = useState(false);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const searchedKey = useSelector(getSearchedKey);
  const uid = useSelector(getUid);
  const activeConId = useSelector(getActiveConId);

  const updateUserStatus = (status: string) => {
    const currentUserRef = ref(firebaseDb, `conversations/${uid}/${activeConId}`);
    update(currentUserRef, { leadState: status }).then(() =>
      setCurrentUser({ ...currentUser, leadState: status })
    );
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseStatusMenu = () => {
    setAnchorSEl(null);
  };

  const handleOpenBug = () => {
    setOpenBug(!openBug);
  };

  useEffect(() => {
    return () => {
      dispatch(activeConIdSet(undefined));
      dispatch(handleOpenChat(false));
    };
  }, []);

  useEffect(() => {
    const handleGetConItem = () => {
      getData(`conversations/${uid}/${searchedKey}`).then((r) => {
        setCurrentUser(r);
      });
    };

    if (searchedKey) handleGetConItem();
  }, [searchedKey]);

  const handleUpdateLead = () => {
    setLeadUpdateDialog(!leadUpdateDialog);
  };

  return (
    <>
      <Grid
        container
        borderRadius={3}
        display={"flex"}
        sx={{
          p: { xs: 0, sm: 1 },
          m: "auto",
          width: "100%",
          height: "100%",
          bgcolor: (theme) => theme.palette.background.paper,
        }}
      >
        <ConversationList setCurrentUser={setCurrentUser} />
        {isXs ? (
          <>
            {activeConId && (
              <ChatList
                setOpenStatus={setOpenStatus}
                openInfo={openInfo}
                setOpenInfo={setOpenInfo}
                currentUser={currentUser}
                setAnchorEl={setAnchorEl}
                setAnchorSEl={setAnchorSEl}
                openAISetting={openAISetting}
                setOpenAISetting={setOpenAISetting}
                openLeadInfo={openLeadInfo}
                setOpenLeadInfo={setOpenLeadInfo}
                openCarInfo={openCarInfo}
                setOpenCarInfo={setOpenCarInfo}
                openReportIssue={openReportIssue}
                setOpenReportIssue={setOpenReportIssue}
                handleOpenBug={handleOpenBug}
              />
            )}
          </>
        ) : (
          <ChatList
            setOpenStatus={setOpenStatus}
            openInfo={openInfo}
            setOpenInfo={setOpenInfo}
            currentUser={currentUser}
            setAnchorEl={setAnchorEl}
            setAnchorSEl={setAnchorSEl}
          />
        )}

        <ChatInfo
          openInfo={openInfo}
          setOpenInfo={setOpenInfo}
          correctUser={currentUser}
          setOpenStatus={setOpenStatus}
          openStatus={openStatus}
          setAnchorEl={setAnchorEl}
          setAnchorSEl={setAnchorSEl}
          handleUpdateLead={handleUpdateLead}
          handleOpenBug={handleOpenBug}
          openAISetting={openAISetting}
          setOpenAISetting={setOpenAISetting}
          openLeadInfo={openLeadInfo}
          setOpenLeadInfo={setOpenLeadInfo}
          openCarInfo={openCarInfo}
          setOpenCarInfo={setOpenCarInfo}
          openReportIssue={openReportIssue}
          setOpenReportIssue={setOpenReportIssue}
        />
      </Grid>
      <Notifications />
      <Menu anchorEl={anchorEl} handleClose={handleCloseMenu}>
        <MenuItem
          onClick={() => {
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <BorderColorRoundedIcon />
          </ListItemIcon>
          <ListItemText>Edit Lead&apos;s Name</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleOpenBug}>
          <ListItemIcon>
            <BugReportRoundedIcon />
          </ListItemIcon>
          <ListItemText>Bug</ListItemText>
        </MenuItem>
      </Menu>
      <Menu anchorEl={anchorSEl} handleClose={handleCloseStatusMenu}>
        <MenuItem onClick={() => updateUserStatus("hot")}>
          <ListItemIcon>
            <LocalFireDepartmentOutlinedIcon
              color={
                currentUser?.appointmentIsSet || currentUser?.leadState === "hot"
                  ? "primary"
                  : "action"
              }
            />
          </ListItemIcon>
          <Typography
            fontWeight={500}
            color={(theme) =>
              currentUser?.appointmentIsSet || currentUser?.leadState === "hot"
                ? theme.palette.primary.main
                : ""
            }
          >
            Hot Lead
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => updateUserStatus("contactAttemped")}>
          <ListItemIcon>
            <WbSunnyOutlinedIcon
              color={
                !currentUser?.appointmentIsSet && currentUser?.leadState === "contactAttemped"
                  ? "primary"
                  : "action"
              }
            />
          </ListItemIcon>
          <Typography
            fontWeight={500}
            color={(theme) =>
              !currentUser?.appointmentIsSet && currentUser?.leadState === "contactAttemped"
                ? theme.palette.primary.main
                : ""
            }
          >
            Warm Lead
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => updateUserStatus("cold")}>
          <ListItemIcon>
            <AcUnitOutlinedIcon
              color={
                !currentUser?.appointmentIsSet && currentUser?.leadState === "cold"
                  ? "primary"
                  : "action"
              }
            />
          </ListItemIcon>
          <Typography
            fontWeight={500}
            color={(theme) =>
              !currentUser?.appointmentIsSet && currentUser?.leadState === "cold"
                ? theme.palette.primary.main
                : ""
            }
          >
            Cold Lead
          </Typography>
        </MenuItem>
      </Menu>
      <BugDialog open={openBug} handleClose={handleOpenBug} />
      <EditLeadName
        open={leadUpdateDialog}
        handleClose={handleUpdateLead}
        setCurrentUser={(data: any) =>
          setCurrentUser({ ...currentUser, firstName: data?.firstName, lastName: data?.lastName })
        }
      />
    </>
  );
}
