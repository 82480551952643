import React, { Suspense, useCallback, useRef } from "react";
import {
  Box,
  Fab,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import { useDispatch, useSelector } from "react-redux";
import {
  getAIMode,
  getIsOpenChat,
  handleOpenChat,
  getActiveConId,
  activeConIdSet,
  AImodeSet,
} from "../../redux/userSlice";
import { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";

import { off, onChildChanged, onValue, push, query, ref, update } from "firebase/database";
import { firebaseDb } from "../../firebase/app";
import { getUid } from "../../redux/authSlice";
import { To, useNavigate } from "react-router";
import ChatItem from "./chat-item";
import { formatPhoneNumber, handleBackground, handleCopyClipboard } from "../../utils/utils";
import ControlledInput from "../../components/input/controlled-input";
import ChatGroupContent from "./chat-group-content";
import { GroupedVirtuoso, GroupedVirtuosoHandle } from "react-virtuoso";
import { groupBy, uniq } from "underscore";
import { getData } from "../../utils/request";
import Snackbar from "../../components/snackbar/snackbar";
import { rotate45deg } from "../../config/constant";
import PhoneIcon from "../../assets/icon/phone";
import CopyIcon from "../../assets/icon/copy";
import { getContactName } from "../../utils/string";
import { getMode } from "../../redux/layoutSlice";
import Button from "../../components/button/button";

const generateGroupedMessages = (chats: any) => {
  const uniqueBy = uniq(Object.values(chats), function (x: any) {
    return x?.timestamp;
  });
  const newData = uniqueBy;
  const groupedMessages = groupBy(newData, (message: any) => {
    const date = new Date(message?.timestamp);
    const today = new Date();

    // Reset time to compare only dates
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    // Compare dates
    if (date >= today) {
      return "Today";
    } else if (date >= yesterday) {
      return "Yesterday";
    } else {
      const year = date.toLocaleString("en-US", { year: "numeric" });
      const month = date.toLocaleString("en-US", { month: "2-digit" });
      const day = date.toLocaleString("en-US", { day: "2-digit" });
      return `${month}.${day}.${year}`;
    }
  });

  const groupCounts = Object.values(groupedMessages).map((item) => item.length);
  const groups = Object.keys(groupedMessages);
  return { groupCounts, groups, newData };
};

const renderChatItem = (
  chats: any,
  setIsScrollBottom: any,
  listRef: React.Ref<GroupedVirtuosoHandle> | undefined,
  currentUser: any
) => {
  const { groupCounts, groups, newData } = generateGroupedMessages(chats);

  return (
    <>
      <GroupedVirtuoso
        // atTopStateChange={e => {
        //   if (e) handleRemoveMessageList()
        // }}
        // startReached={e => console.log('startReached', e)}
        initialTopMostItemIndex={Object.values(chats).length - 1}
        //
        atBottomStateChange={(e: any) => {
          if (e) setIsScrollBottom(e);
          else setIsScrollBottom(e);
        }}
        followOutput={"smooth"}
        groupContent={(index: any) => (
          <ChatGroupContent groups={groups.filter((item) => item !== "undefined")} index={index} />
        )}
        groupCounts={groupCounts}
        itemContent={(index) => {
          return <ChatItem value={newData[index]} currentUser={currentUser} />;
        }}
        style={{ height: "100%" }}
        // endReached={e => console.log('endReached', e)}
        ref={listRef}
      />
    </>
  );
};

const ChatList = ({
  currentUser,
  openAISetting,
  setOpenAISetting,
  openLeadInfo,
  setOpenLeadInfo,
  openCarInfo,
  setOpenCarInfo,
  handleOpenBug,
}: any) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages]: any = useState({});
  const [isScrollBottom, setIsScrollBottom] = useState(false);
  const [AITempDi, setAITempDi] = useState(false);
  const [state, setState] = useState({ message: "", open: false, type: "" });
  const [isExpanded] = useState(true);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const mode = useSelector(getMode);
  const listRef: any = useRef(null);
  const dispatch = useDispatch();
  const AImode = useSelector(getAIMode);
  const navigate = useNavigate();
  const isOpenChat = useSelector(getIsOpenChat);
  const uid = useSelector(getUid);
  const activeConId = useSelector(getActiveConId);

  const getMessages = (activeConId: any) => {
    const databaseRef = query(ref(firebaseDb, `messages/${uid}/${activeConId}`));
    onValue(
      databaseRef,
      async (snapshot) => {
        if (snapshot.exists()) {
          const values: any = [];
          snapshot.forEach(function (childSnapshot) {
            const childKey = childSnapshot.key;
            const childData = childSnapshot.val();
            if (!childData.timestamp) return;
            childData["pushId"] = childKey;
            values.push(childData);
          });

          setMessages(values);
        } else {
          console.log("error");
        }
      },
      { onlyOnce: true }
    );
  };

  useEffect(() => {
    const chatRef = ref(firebaseDb, `messages/${uid}/${activeConId}`);
    onChildChanged(chatRef, (snapshot) => {
      const data = snapshot.val();
      const key = snapshot.key;
      data["pushId"] = key;
      if (data?.timestamp && data?.pushId) {
        setMessages((prevState: any) => [...prevState, data]);
      }
    });

    return () => {
      off(chatRef);
    };
  }, [activeConId, messages]);

  const sendUserMessages = () => {
    const data = {
      content: message.trim(),
      receive: false,
      read: true,
      sender: "Sender",
      service: {
        SMS: true,
        email: false,
        widget: false,
      },
    };
    if (activeConId && data) {
      push(ref(firebaseDb, `messages/${uid}/${activeConId}`), data).then(() => setMessage(""));
    }
  };

  const handleChangeMessage = useCallback((e: any) => {
    setMessage(e.target.value);
  }, []);

  function handleNavigate(to: To) {
    navigate(to);
  }

  function handleBack() {
    dispatch(handleOpenChat(false));
    handleNavigate("/");
    dispatch(activeConIdSet(undefined));
  }

  function handleKeyPress(e: any) {
    if (e.key === "Enter") {
      sendUserMessages();
    }
  }

  // listen for the setAITempDi
  useEffect(() => {
    const currentRef = ref(firebaseDb, `conversations/${uid}/${activeConId}/AIMode`);
    onValue(currentRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        dispatch(AImodeSet(data));
      }
    });
    return () => {
      off(currentRef);
    };
  }, [activeConId]);

  // listen for the setAITempDi
  useEffect(() => {
    const currentRef = ref(firebaseDb, `conversations/${uid}/${activeConId}/AITemporaryDisable`);
    onValue(currentRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setAITempDi(data);
      }
    });
    return () => {
      off(currentRef);
    };
  }, [activeConId]);

  useEffect(() => {
    if (activeConId) {
      getData(`conversations/${uid}/${activeConId}/AITemporaryDisable`).then((r: any) =>
        setAITempDi(r)
      );
      getMessages(activeConId);
    }
  }, [activeConId]);

  function changeAITemporaryDisabled() {
    const conversationRef = ref(firebaseDb, `conversations/${uid}/${activeConId}`);
    update(conversationRef, { AITemporaryDisable: true }).then(() => {
      setState({ message: "A.I. temporary disabled for 3 minutes", open: true, type: "" });
      setAITempDi(true);
    });
  }

  const handleAI = () => {
    if (AITempDi) return;
    changeAITemporaryDisabled();
  };

  const scrollToIndex = () => {
    listRef?.current?.scrollIntoView({
      behavior: "smooth",
      index: Object.values(messages).length,
    });
  };

  const renderMobile = (
    <Suspense>
      <Box
        position={"fixed"}
        top={0}
        borderRadius={3}
        width={"100%"}
        bgcolor={(theme) => theme.palette.background.paper}
        p={1}
        display={"flex"}
        height={isExpanded ? 127 : 77}
        sx={{
          alignItems: "center",
          transition: "all .5s ease" /* Transition effect */,
        }}
        flexDirection={"column"}
      >
        <Box display={"flex"} width={"100%"}>
          <IconButton
            onClick={handleBack}
            color="inherit"
            sx={{
              pt: 0,
              display: "flex",
              alignItems: "baseline",
            }}
          >
            <ArrowBackIcon fontSize="medium" />
          </IconButton>
          {handleBackground(currentUser, false, "small")}
          <Box width={"100%"}>
            <Typography fontWeight={"bold"} fontSize={18} ml={1}>
              {getContactName(currentUser)}
            </Typography>
            <Box display={"flex"} alignItems={"center"} width={"100%"}>
              <Box display={"flex"}>
                <Box mr={1} display={"flex"} alignItems={"center"}>
                  <PhoneIcon sx={{ fontSize: 18 }} />
                </Box>
                <Typography
                  fontWeight={600}
                  variant="body1"
                  display={"flex"}
                  alignItems={"center"}
                  color="#8F92A1"
                >
                  {currentUser?.phoneNumber && formatPhoneNumber(currentUser?.phoneNumber)}
                </Typography>
                <IconButton
                  onClick={() => {
                    handleCopyClipboard(currentUser?.phoneNumber);
                    setState({
                      message: "Phone successfully copy.",
                      open: true,
                      type: "",
                    });
                  }}
                >
                  <CopyIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          display={isExpanded ? "flex" : "none"}
          sx={{
            transition: "all .5s ease" /* Transition effect */,
          }}
          width={"100%"}
          justifyContent={"space-between"}
        >
          <Button
            variant="outlined"
            color="primary"
            size="small"
            sx={{
              bgcolor: (theme: any) => theme.palette.secondary.main,
              fontSize: 12,
              border: "1px solid #07A4FC",
            }}
            onClick={() => setOpenAISetting(!openAISetting)}
          >
            A.I. Setting
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            sx={{
              bgcolor: (theme: any) => theme.palette.secondary.main,
              fontSize: 12,
              border: "1px solid #07A4FC",
            }}
            onClick={() => setOpenLeadInfo(!openLeadInfo)}
          >
            Lead Info
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            sx={{
              bgcolor: (theme: any) => theme.palette.secondary.main,
              fontSize: 12,
              border: "1px solid #07A4FC",
            }}
            onClick={() => setOpenCarInfo(!openCarInfo)}
          >
            Car Info
          </Button>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            sx={{
              bgcolor: (theme: any) => theme.palette.secondary.main,
              fontSize: 12,
              border: "1px solid #07A4FC",
            }}
            onClick={handleOpenBug}
          >
            Report Issue
          </Button>
        </Box>
      </Box>
    </Suspense>
  );

  return (
    <>
      <Grid
        item
        xs={isOpenChat ? 12 : false}
        md={isOpenChat ? 7 : 9}
        lg={isOpenChat ? 5 : 8}
        sx={{ p: { xs: 0, sm: 2 } }}
        display={"flex"}
        height={"100%"}
      >
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          position={"relative"}
          bgcolor={(theme) => theme.palette.common.white}
          borderRadius={3}
          sx={{
            height: "100%",
            overflow: "hidden",
          }}
        >
          {!isOpenChat ? (
            <Box textAlign={"center"} margin={"auto"}>
              <Box display={"flex"} justifyContent={"center"}>
                <Box display={"flex"} width={"100%"} justifyContent={"center"}>
                  <img
                    alt="infinity logo"
                    src={mode === "dark" ? "/images/light-logo.png" : "/images/dark-logo.png"}
                    loading="lazy"
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <>
              {isXs && renderMobile}
              <Stack
                sx={{
                  margin: { xs: isExpanded ? "8rem 0 0rem 0" : "5.5em 0 1rem 0", sm: 0 },
                  height: "100%",
                  transition: "margin .5s ease",
                  pt: 1,
                }}
              >
                {Object.values(messages)?.length > 0 &&
                  renderChatItem(messages, setIsScrollBottom, listRef, currentUser)}
              </Stack>

              {!isScrollBottom && (
                <Box
                  display={"flex"}
                  justifyContent={"end"}
                  p={1}
                  position={"absolute"}
                  right={0}
                  bottom={"4rem"}
                >
                  <Fab color="inherit" aria-label="add" onClick={scrollToIndex}>
                    <ArrowDownwardRoundedIcon />
                  </Fab>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  pb: 2,
                }}
              >
                <Box
                  sx={{
                    width: "95%",
                    background: "white",
                    p: "12px 8px",
                    borderRadius: 3,
                  }}
                >
                  <Box display={"flex"}>
                    <ControlledInput
                      startAdornment={
                        <Box
                          sx={{
                            rotate: "135deg",
                            animation: `${AITempDi ? rotate45deg : ""} 2s infinite linear`,
                            mr: 1,
                          }}
                        >
                          <img
                            alt="infinity logo"
                            src="/images/infinitive.svg"
                            style={{ width: "2rem", height: "2rem" }}
                            loading="lazy"
                          />
                        </Box>
                      }
                      endAdornment={
                        <IconButton
                          onClick={sendUserMessages}
                          disabled={(!AITempDi && AImode) || message === ""}
                        >
                          <SendIcon color="primary" />
                        </IconButton>
                      }
                      placeholder={"write a message"}
                      value={message}
                      onKeyDown={handleKeyPress}
                      onChange={handleChangeMessage}
                      onClick={handleAI}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}
          <Snackbar
            handleClose={() => setState({ message: "", open: false, type: "" })}
            message={state.message}
            open={state.open}
            type={state.type}
            mobile={isXs}
          />
        </Box>
      </Grid>
    </>
  );
};

export default ChatList;
