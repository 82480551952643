import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { Stack, useMediaQuery, useTheme } from "@mui/material";

import LeftSidebar from "./left-sidebar";
import Navbar from "./navbar";
import { bottomHeight, navAndBottomHeightPx, navHeight, navHeightPx } from "../config/constant";
import BottomNavigation from "./navigation";
import { useSelector } from "react-redux";
import { getActiveConId } from "../redux/userSlice";

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    "& .MuiDrawer-paper": {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      borderRight: "none",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9),
        },
      }),
    },
  })
);

export default function DashboardLayout({ children }: any) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const activeConId = useSelector(getActiveConId);

  const handleOpen = () => {
    setOpen(!open);
  };

  const renderLeftDrawer = (
    <>
      {isSmall ? (
        <MuiDrawer anchor={"left"} open={open} onClose={handleOpen}>
          <List
            component="nav"
            sx={{
              borderRight: "none",
              width: 100,
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <LeftSidebar />
          </List>
        </MuiDrawer>
      ) : (
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            paddingTop: navHeight,
          }}
        >
          <List
            component="nav"
            sx={{
              borderRight: "none",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <LeftSidebar openSidebar={open} />
          </List>
        </Drawer>
      )}
    </>
  );

  return (
    <Box sx={{ display: "flex", background: (theme) => theme.palette.background.paper }}>
      {isSmall ? !activeConId && <Navbar setOpen={handleOpen} /> : <Navbar setOpen={handleOpen} />}
      {renderLeftDrawer}
      <Box
        component="main"
        height={
          isSmall
            ? activeConId
              ? "100dvh"
              : `calc(100vh - ${navAndBottomHeightPx})`
            : `calc(100vh - ${navHeightPx})`
        }
        sx={{
          flexGrow: 1,
          overflow: "auto",
          m: isSmall
            ? activeConId
              ? 0
              : theme.spacing(`${navHeightPx} 0 ${bottomHeight}px 0`)
            : theme.spacing(`${navHeightPx} 0 0 0`),
        }}
      >
        <Stack
          p={{
            xs: 0,
            sm: 1,
            height: "100%",
          }}
          sx={{
            borderTopLeftRadius: 30,
            backgroundColor: (theme) => theme.palette.background.default,
          }}
        >
          <Stack
            sx={{
              m: "auto",
              width: "100%",
              height: isSmall ? (activeConId ? "100%" : "95%") : "95%",
              borderRadius: 3,
            }}
          >
            {children}
          </Stack>
        </Stack>
      </Box>
      {isSmall && !activeConId && <BottomNavigation />}
    </Box>
  );
}
