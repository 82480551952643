import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MessageRoundedIcon from "@mui/icons-material/MessageRounded";
import PhoneInTalkRoundedIcon from "@mui/icons-material/PhoneInTalkRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import { getUid } from "../redux/authSlice";
import { getData, getDataLTL } from "../utils/request";
import { useSelector } from "react-redux";
import Lead from "../sections/report/lead";
import { formatTimestamp } from "../utils/time";
import LineChartPreview from "../components/line-chart-preview";
import { getMode } from "../redux/layoutSlice";
import Button from "../components/button/button";

const CustomStack = styled(Stack)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-around",
  p: 2,
  m: 1,
  backgroundColor: theme.palette.background.paper,
  borderRadius: 2,
  alignItems: "center",
  gap: theme.spacing(1),
}));

export default function Report() {
  const uid = useSelector(getUid);

  const [dailyStatistics, setDailyStatistics] = useState([]);
  const [dailyStatisticsStatus, setDailyStatisticsStatus] = useState("convsNum");
  const mode = useSelector(getMode);
  const [state, setState]: any = useState({
    S2TNum: 0,
    afterHourLeadsNum: 0,
    apptsNum: 0,
    convsNum: 0,
    followUpNum: 0,
    leadsNum: 0,
    messagesNum: 0,
    missedCallsNum: 0,
    sourcesNum: {},
    usedS2TNum: 0,
  });

  const [adPlatforms, setAdPlatforms]: any[] = useState([]);
  const [dayFilter, setDayFilter]: any = useState("YTD");

  const getAdPlatforms = (data: any[]) => {
    const values: { key: string; count: number; logoLink: string; name: string }[] = [];

    Object.entries(data)
      .sort((a: any, b: any) => b[1] - a[1])
      .map(([key, item]: any) => {
        const value = {
          key: "",
          count: 0,
          logoLink: "",
          name: "",
        };

        value.key = key;
        value.count = item;
        values.push(value);
      });
    setAdPlatforms(values);
  };

  function handleGetStatistics(filter: string | number) {
    getData(`statistics/${uid}/${filter}`).then((r: any) => {
      setState(r);
    });
  }

  function handleGetDailyStatistics(limit: any) {
    getDataLTL(`dailyStatistics/${uid}`, limit, "timestamp").then((r: any) => {
      Object.values(r).forEach((item: any): any => {
        item.timestamp = formatTimestamp(item?.timestamp);
      });
      setDailyStatistics(Object.values(r));
    });
  }

  useEffect(() => {
    handleGetStatistics(dayFilter);
    // set default for graph
    handleGetDailyStatistics(360);
  }, []);

  const handleClickDayFilter = (filter: number | string) => {
    handleGetStatistics(filter);
    handleGetDailyStatistics(filter === "YTD" ? 360 : filter);
    setDayFilter(filter);
  };

  useEffect(() => {
    if (!state.sourcesNum) return;
    getAdPlatforms(state.sourcesNum);
  }, [state.sourcesNum]);

  const handleChange = (event: SelectChangeEvent) => {
    setDailyStatisticsStatus(event.target.value as string);
  };

  return (
    <Box
      sx={{
        bgcolor: (theme) => theme.palette.background.paper,
        borderRadius: 4,
      }}
    >
      <Box display={"flex"} justifyContent={"space-between"} p={1} flexWrap={"wrap"}>
        <Typography variant="h5" fontWeight={600}>
          Overview
        </Typography>
        <Box gap={1} display={"flex"}>
          <Button
            variant={dayFilter === 7 ? "contained" : "outlined"}
            onClick={() => handleClickDayFilter(7)}
          >
            7 days
          </Button>
          <Button
            variant={dayFilter === 30 ? "contained" : "outlined"}
            onClick={() => handleClickDayFilter(30)}
          >
            30 days
          </Button>
          <Button
            variant={dayFilter === 90 ? "contained" : "outlined"}
            onClick={() => handleClickDayFilter(90)}
          >
            90 days
          </Button>
          <Button
            variant={dayFilter === "YTD" ? "contained" : "outlined"}
            onClick={() => handleClickDayFilter("YTD")}
          >
            YTD
          </Button>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }} mt={2}>
        <Grid container spacing={1}>
          <Grid
            item
            lg={7}
            md={6}
            xs={12}
            alignContent={"center"}
            alignItems={"baseline"}
            display={"flex"}
          >
            <CustomStack
              sx={{
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {[
                {
                  icon: <CalendarMonthRoundedIcon color="primary" />,
                  num: state.messagesNum,
                  title: "Messages",
                  caption: "Tracks the total number of messages sent to customers.",
                },
                {
                  icon: <MessageRoundedIcon color="primary" />,
                  num: state.convsNum,
                  title: "Conversation",
                  caption: "Shows the total number of ongoing conversations.",
                },
                {
                  icon: <CalendarMonthRoundedIcon color="primary" />,
                  num: state.apptsNum,
                  title: "Appointment Booked",
                  caption: " Displays the number of appointments set during conversations.",
                },
                {
                  icon: <PhoneInTalkRoundedIcon color="primary" />,
                  num: state.afterHourLeadsNum,
                  title: "After Hour",
                  caption: "Shows the number of leads responded to outside working hours.",
                },
                {
                  icon: <PhoneInTalkRoundedIcon color="primary" />,
                  num: state.missedCallsNum,
                  title: "Missed Calls",
                  caption: " Indicates how many conversations started due to missed calls.",
                },
                {
                  icon: <MessageRoundedIcon color="primary" />,
                  num: state?.usedS2TNum,
                  title: "Switch To Text",
                  caption:
                    "Counts the leads converted from email to text by obtaining phone numbers.",
                },
              ].map((i, id) => (
                <Box
                  key={id}
                  bgcolor={mode === "dark" ? "#313645" : "white"}
                  p={2}
                  m={{ xs: 0, sm: 1 }}
                  borderRadius={3}
                  textAlign={"center"}
                  minHeight={"18vh"}
                  width={{ xs: 200, md: 300 }}
                  border="1px solid rgba(0, 0, 0, 0.09)"
                >
                  <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                    <Box>{i.icon}</Box>
                    <Box display={"flex"} flexDirection={"column"}>
                      <Typography variant="body1" fontWeight={"bold"}>
                        {i.title}
                      </Typography>
                      <Typography variant="h4" fontWeight={"bold"}>
                        {i.num}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant="body2" textAlign={"left"}>
                      {i.caption}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </CustomStack>
          </Grid>
          <Grid item lg={5} md={6} xs={12}>
            <Stack
              display={"flex"}
              p={2}
              m={1}
              borderRadius={3}
              gap={1}
              border="1px solid rgba(0, 0, 0, 0.09)"
              height={"30rem"}
              bgcolor={mode === "dark" ? "#313645" : "white"}
            >
              {adPlatforms?.length > 0 && (
                <>
                  <Typography variant="h6" p={1}>
                    Top Lead Source
                  </Typography>
                  <Divider />
                  <List
                    sx={{
                      overflowY: "scroll",
                      borderRadius: "1rem",
                      m: 2,
                    }}
                  >
                    {adPlatforms?.map((a: any, id: any): any => (
                      <Lead data={a} key={id} id={id} leadsNum={state.leadsNum} />
                    ))}
                  </List>
                </>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Stack
        bgcolor={(theme) => theme.palette.background.default}
        borderRadius={3}
        display={"block"}
        flexDirection={"row"}
        justifyContent={"space-around"}
        m={2}
        p={2}
      >
        <Box display={"flex"} justifyContent={"space-between"} m={1}>
          <Box>
            <Typography variant="h5" fontWeight={600} my={1}>
              Performance in {dayFilter} {dayFilter !== "YTD" && "days"}
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"end"} sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{dailyStatisticsStatus}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={dailyStatisticsStatus}
                label="apptsNum"
                onChange={handleChange}
              >
                <MenuItem value={"apptsNum"}>appointment</MenuItem>
                <MenuItem value={"convsNum"}>conversation</MenuItem>
                <MenuItem value={"leadsNum"}>leads</MenuItem>
                <MenuItem value={"messagesNum"}>messages</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box bgcolor={(theme) => theme.palette.background.paper} borderRadius={3}>
          {dailyStatistics?.length > 0 && (
            <LineChartPreview
              dailyStatistics={dailyStatistics}
              dailyStatisticsStatus={dailyStatisticsStatus}
            />
          )}
        </Box>
      </Stack>
    </Box>
  );
}
