import React from "react";
import { Box, Typography } from "@mui/material";
import Dialog from "../../components/dialog/dialog";
import { getAuth, signOut } from "firebase/auth";
import { useDispatch } from "react-redux";
import { authSet, logout } from "../../redux/authSlice";
import { firebaseApp } from "../../firebase/app";
import { useNavigate } from "react-router-dom";
import { handleOpenChat } from "../../redux/userSlice";
import Button from "../../components/button/button";

export default function LogOut({ open, handleClose }: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleNavigate() {
    navigate("/login");
  }

  const handleLogout = () => {
    const auth = getAuth(firebaseApp);

    signOut(auth)
      .then(() => {
        // Sign-out successful.
        dispatch(authSet(undefined));
        dispatch(logout());
        handleClose();
        handleNavigate();
      })
      .catch((error) => console.log(error));
    dispatch(handleOpenChat(false));
  };

  const renderTitle = "Log Out";

  const renderContent = (
    <Typography variant="body2" fontWeight={700}>
      Are you sure you want to log out?
    </Typography>
  );
  const renderActions = (
    <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={1}>
      <Button variant="contained" onClick={handleLogout} sx={{ width: "100%" }}>
        Ok
      </Button>
    </Box>
  );

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      renderActions={renderActions}
      renderContent={renderContent}
      renderTitle={renderTitle}
      showCloseButton
      filter={false}
    />
  );
}
