import { SvgIcon } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { getMode } from "../../redux/layoutSlice";

export default function CopyIcon() {
  const mode = useSelector(getMode);
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      sx={{ color: mode === "dark" ? "#fff" : "#656565" }}
    >
      <path d="M14.25 4.6875H13.6875V12C13.6855 12.6459 13.4281 13.2647 12.9714 13.7214C12.5147 14.1781 11.8959 14.4355 11.25 14.4375H5.4375V15C5.43947 15.3475 5.57838 15.6802 5.8241 15.9259C6.06981 16.1716 6.40251 16.3105 6.75 16.3125H14.25C14.5975 16.3105 14.9302 16.1716 15.1759 15.9259C15.4216 15.6802 15.5605 15.3475 15.5625 15V6C15.5605 5.65251 15.4216 5.31981 15.1759 5.0741C14.9302 4.82838 14.5975 4.68947 14.25 4.6875Z" />
      <path d="M11.25 13.3125C11.5975 13.3105 11.9302 13.1716 12.1759 12.9259C12.4216 12.6802 12.5605 12.3475 12.5625 12V3C12.5605 2.65251 12.4216 2.31981 12.1759 2.0741C11.9302 1.82838 11.5975 1.68947 11.25 1.6875H3.75C3.40251 1.68947 3.06981 1.82838 2.8241 2.0741C2.57838 2.31981 2.43947 2.65251 2.4375 3V12C2.43947 12.3475 2.57838 12.6802 2.8241 12.9259C3.06981 13.1716 3.40251 13.3105 3.75 13.3125H11.25Z" />
    </SvgIcon>
  );
}
